import React from 'react';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import Button from './ui/Button';

const ArtifactNavigation = ({ onPrevious, onNext, navigation }) => {
  return (
    <div className="flex items-center gap-1">
      <button
        onClick={onPrevious}
        disabled={!navigation?.previous}
        className={`p-1 rounded ${
          !navigation?.previous 
            ? 'text-gray-500' 
            : 'text-gray-300 hover:text-white hover:bg-gray-700'
        }`}
        title={navigation?.previous ? "View previous document" : "No previous document"}
      >
        <ArrowLeft size={16} />
      </button>
      <button
        onClick={onNext}
        disabled={!navigation?.next}
        className={`p-1 rounded ${
          !navigation?.next 
            ? 'text-gray-500' 
            : 'text-gray-300 hover:text-white hover:bg-gray-700'
        }`}
        title={navigation?.next ? "View next document" : "No next document"}
      >
        <ArrowRight size={16} />
      </button>
    </div>
  );
};

export default ArtifactNavigation; 