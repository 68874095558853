import React, { useState, useEffect } from 'react';
import Button from './ui/Button';

const UserManager = ({ api, setShowUserManager }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('all'); // 'all', 'approved', 'pending'

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await api.get('/users/waitlist/entries');
            setUsers(response.data);
        } catch (err) {
            setError('Failed to fetch waitlist users');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleApproveUser = async (email) => {
        try {
            await api.post(`/users/waitlist/approve/${email}`);
            await api.post('/users/waitlist/send-approval-email', { email });
            await fetchUsers();
        } catch (err) {
            setError('Failed to approve user');
            console.error(err);
        }
    };

    const filteredUsers = users.filter(user => {
        if (filter === 'approved') return user.status === 'approved';
        if (filter === 'pending') return user.status === 'pending';
        return true; // 'all' filter
    });

    if (loading) return <div className="text-center p-4">Loading...</div>;
    if (error) return <div className="text-center text-red-500 p-4">{error}</div>;

    return (
        <div className="flex flex-col items-center w-full max-w-4xl mx-auto px-4">
            <div className="w-full">
                {/* Filter buttons */}
                <div className="flex gap-4 mb-4">
                    <Button
                        onClick={() => setFilter('all')}
                        variant={filter === 'all' ? 'primary' : 'secondary'}
                        size="sm"
                    >
                        All Users
                    </Button>
                    <Button
                        onClick={() => setFilter('approved')}
                        variant={filter === 'approved' ? 'primary' : 'secondary'}
                        size="sm"
                    >
                        Approved
                    </Button>
                    <Button
                        onClick={() => setFilter('pending')}
                        variant={filter === 'pending' ? 'primary' : 'secondary'}
                        size="sm"
                    >
                        Pending
                    </Button>
                </div>

                <div className="bg-gray-800 rounded-lg overflow-hidden mb-6">
                    <div className="max-h-[70vh] overflow-y-auto">
                        <table className="min-w-full divide-y divide-gray-700">
                            <thead className="bg-gray-900 sticky top-0 z-10">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Email</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Status</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Tier</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Joined</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-700">
                                {filteredUsers.map((user) => (
                                    <tr key={user.email}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{user.email}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                                                ${user.status === 'approved' ? 'bg-green-800 text-green-100' : 
                                                  user.status === 'pending' ? 'bg-yellow-800 text-yellow-100' : 
                                                  'bg-red-800 text-red-100'}`}>
                                                {user.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                            {user.tier || 'STANDARD'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                            {new Date(user.created_at * 1000).toLocaleDateString()}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                            {user.status !== 'approved' && (
                                                <Button
                                                    onClick={() => handleApproveUser(user.email)}
                                                    variant="primary"
                                                    size="sm"
                                                >
                                                    Approve
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex justify-center">
                    <Button
                        onClick={() => setShowUserManager(false)}
                        variant="secondary"
                    >
                        Back to Dashboard
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default UserManager; 