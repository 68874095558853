import React from 'react';
import { Link, FileText, Trash2, Loader } from 'lucide-react';

const DataSources = ({
  dataSources = [],
  onRemoveDataSource,
  isLoadingDataSources,
  deletingDataSourceId,
  setIsCollapsed,
  isSmallScreen,
}) => {
  if (isLoadingDataSources) {
    return (
      <div className="flex justify-center items-center py-4">
        <Loader size={24} className="animate-spin text-gray-400" />
      </div>
    );
  }

  return (
    <div className="space-y-2 bg-gray-900/50 p-3 rounded-md shadow-lg">
      <div className="space-y-1.5">
        {dataSources.map((source) => (
          <div
            key={source.id}
            className="group relative flex items-center gap-3 px-3 py-2 
              bg-gray-800/40 hover:bg-gray-700/40 
              rounded-md transition-all duration-200
              hover:shadow-lg hover:shadow-blue-500/5 
              hover:translate-x-0.5"
          >
            <div className="flex-shrink-0">
              {source.data_type === 'url' ? (
                <Link size={16} className="text-blue-400" />
              ) : (
                <FileText size={16} className="text-green-400" />
              )}
            </div>
            <div className="flex-1 min-w-0">
              <div 
                className="text-xs font-medium text-gray-200 line-clamp-2"
                title={source.name || source.url || source.file_name}
              >
                {source.name || source.url || source.file_name}
              </div>
            </div>
            <div
              onClick={() => {
                onRemoveDataSource(source.id);
                if (isSmallScreen) {
                  setIsCollapsed(true);
                }
              }}
              className="opacity-0 group-hover:opacity-100 cursor-pointer"
            >
              {deletingDataSourceId === source.id ? (
                <Loader size={16} className="animate-spin text-red-400" />
              ) : (
                <Trash2 size={16} className="text-red-400 hover:text-red-300 transition-colors duration-150" />
              )}
            </div>
          </div>
        ))}
        {dataSources.length === 0 && (
          <div className="text-sm text-gray-500 p-3 
            bg-gray-800/20 rounded-md
            text-center">
            No data sources added
          </div>
        )}
      </div>
    </div>
  );
};

export default DataSources; 