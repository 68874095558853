import { useState, useEffect, useCallback, useRef } from 'react';
import useApi from './useApi';
import { handleHttpError } from '../utils/errorHandling';

const useArtifacts = (selectedProjectId, selectedThreadId, selectedArtifactId) => {
  const { api } = useApi();
  const [activeArtifacts, setActiveArtifacts] = useState([]);
  const [availableArtifacts, setAvailableArtifacts] = useState([]);
  const [isLoadingArtifacts, setIsLoadingArtifacts] = useState(false);
  const [currentArtifact, setCurrentArtifact] = useState(null);
  const [explanationStatus, setExplanationStatus] = useState(null);
  const pollInterval = useRef(null);

  const fetchArtifacts = useCallback(async () => {
    if (!selectedProjectId) {
      setActiveArtifacts([]);
      setAvailableArtifacts([]);
      return;
    }

    try {
      setIsLoadingArtifacts(true);
      
      // First fetch all project artifacts
      const projectResponse = await api.get(`/artifacts/${selectedProjectId}`);
      const projectArtifacts = projectResponse.data.artifacts || [];
      
      // Then fetch conversation artifacts if there's a thread
      let conversationArtifacts = [];
      if (selectedThreadId) {
        const conversationResponse = await api.get(`/artifacts/${selectedProjectId}`, {
          params: { conversation_id: selectedThreadId }
        });
        conversationArtifacts = conversationResponse.data.artifacts || [];
      }
      
      // Set both states
      setAvailableArtifacts(projectArtifacts);
      setActiveArtifacts(selectedThreadId ? conversationArtifacts : projectArtifacts);

    } catch (error) {
      console.error('Error fetching artifacts:', error);
      handleHttpError(error, 'fetching artifacts');
    } finally {
      setIsLoadingArtifacts(false);
    }
  }, [api, selectedProjectId, selectedThreadId]);

  // Expose fetchArtifacts as refreshArtifacts
  const refreshArtifacts = useCallback(() => {
    return fetchArtifacts();
  }, [fetchArtifacts]);

  // Single effect to handle both project and thread changes
  useEffect(() => {
    if (selectedProjectId) {
      // Only clear active artifacts when project changes
      if (!selectedThreadId) {
        setActiveArtifacts([]);
      }
      fetchArtifacts();
    }
  }, [selectedProjectId, selectedThreadId, fetchArtifacts]);

  const onRemoveArtifact = async (artifactId) => {
    if (!selectedProjectId || !selectedThreadId) {
      console.warn('Cannot delete artifacts outside of a conversation');
      return;
    }

    try {
      await api.delete(`/artifacts/${selectedProjectId}/${artifactId}`, {
        params: { conversation_id: selectedThreadId }
      });
      
      // Only update activeArtifacts since we only delete from conversations
      setActiveArtifacts(prev => prev.filter(artifact => artifact.id !== artifactId));

      // Refresh conversation artifacts
      await fetchArtifacts();
    } catch (error) {
      console.error('Error removing artifact:', error);
      handleHttpError(error, 'removing artifact');
      throw error;
    }
  };

  const getArtifact = useCallback(async (artifactId) => {
    if (!selectedProjectId || !artifactId) return null;
    
    try {
      const response = await api.get(`/artifacts/${selectedProjectId}/${artifactId}`);
      return response.data;
    } catch (error) {
      console.error('Error getting artifact:', error);
      handleHttpError(error, 'fetching artifact');
      return null;
    }
  }, [api, selectedProjectId]);

  // Update current artifact when selected
  useEffect(() => {
    if (selectedArtifactId) {
      setIsLoadingArtifacts(true);
      getArtifact(selectedArtifactId).then(artifact => {
        if (artifact) {
          setCurrentArtifact(artifact);
        }
        setIsLoadingArtifacts(false);
      });
    } else {
      setCurrentArtifact(null);
    }
  }, [selectedArtifactId, getArtifact]);

  const pollExplanationStatus = useCallback(async () => {
    if (!selectedArtifactId || !selectedProjectId) return;

    try {
        const response = await api.get(
            `/artifacts/${selectedProjectId}/${selectedArtifactId}/status`
        );
        
        if (response.data.status === 'completed') {
            // Update current artifact with the explanation data
            const artifactResponse = await api.get(
                `/artifacts/${selectedProjectId}/${selectedArtifactId}`
            );
            setCurrentArtifact(artifactResponse.data);
            setExplanationStatus('completed');
            
            // Clear polling interval immediately on completion
            if (pollInterval.current) {
                clearInterval(pollInterval.current);
                pollInterval.current = null;
            }
        } else if (response.data.status === 'error') {
            setExplanationStatus('error');
            console.error('Explanation generation failed:', response.data.error);
        } else {
            setExplanationStatus(response.data.status || 'pending');
        }
    } catch (error) {
        console.error('Error polling explanation status:', error);
        setExplanationStatus('error');
    }
  }, [api, selectedProjectId, selectedArtifactId]);

  // Reset explanation status when artifact changes
  useEffect(() => {
    const artifact = currentArtifact;
    if (!artifact) return;

    const version = artifact.versions?.find(v => v.is_active) || artifact.versions?.[0];
    if (version) {
      setExplanationStatus(version.explanation?.status || null);
    }
  }, [currentArtifact]);

  // Start polling when explanation is pending
  useEffect(() => {
    if (explanationStatus === 'pending' && !pollInterval.current) {
      pollExplanationStatus();
      pollInterval.current = setInterval(pollExplanationStatus, 5000);
    }
    
    // Cleanup interval when component unmounts or status changes
    return () => {
      if (pollInterval.current) {
        clearInterval(pollInterval.current);
        pollInterval.current = null;
      }
    };
  }, [explanationStatus]);

  const evaluateArtifact = useCallback(async (artifactId) => {
    if (!selectedProjectId || !selectedThreadId || !artifactId) return null;

    try {
      const response = await api.post(
        `/artifacts/${selectedProjectId}/${artifactId}/evaluate`,
        null,
        { params: { conversation_id: selectedThreadId } }
      );
      
      // Update current artifact with evaluation results
      if (currentArtifact && currentArtifact.id === artifactId) {
        setCurrentArtifact(response.data);
      }
      
      return response.data;
    } catch (error) {
      console.error('Error evaluating artifact:', error);
      handleHttpError(error, 'evaluating artifact');
      return null;
    }
  }, [api, selectedProjectId, selectedThreadId, currentArtifact]);

  const refreshSingleArtifact = useCallback(async (artifactId) => {
    if (!selectedProjectId || !artifactId) return;

    try {
      const response = await api.get(`/artifacts/${selectedProjectId}/${artifactId}`);
      setActiveArtifacts(prevArtifacts => {
        const updatedArtifacts = [...prevArtifacts];
        const index = updatedArtifacts.findIndex(a => a.id === artifactId);
        if (index !== -1) {
          updatedArtifacts[index] = response.data;
        }
        return updatedArtifacts;
      });
    } catch (error) {
      console.error('Error refreshing single artifact:', error);
    }
  }, [api, selectedProjectId]);

  const generateExplanation = useCallback(async (artifactId, conversationId, forceRegenerate = false) => {
    try {
      const response = await api.post(
        `/artifacts/${selectedProjectId}/${artifactId}/explain`,
        null,
        {
          params: {
            conversation_id: conversationId,
            force_regenerate: forceRegenerate
          }
        }
      );
      
      // Set status to pending to start polling
      setExplanationStatus('pending');
      
      return response.data;
    } catch (error) {
      console.error('Error generating explanation:', error);
      handleHttpError(error, 'generating explanation');
      throw error;
    }
  }, [api, selectedProjectId]);

  return {
    activeArtifacts,
    availableArtifacts, 
    onRemoveArtifact,
    isLoadingArtifacts,
    refreshArtifacts,
    currentArtifact,
    getArtifact,
    explanationStatus,
    evaluateArtifact,
    refreshSingleArtifact,
    generateExplanation,
  };
};

export default useArtifacts;