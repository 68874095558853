import React, { useEffect, useState, useRef } from 'react';
import { Headphones, X, AlertCircle, Loader2, RefreshCw } from 'lucide-react';
import useArtifacts from '../hooks/useArtifacts';
import StatusPopup from './StatusPopup';

const PodcastExplanation = ({ explanation, onClose, projectId }) => {
  const { explanationStatus, generateExplanation } = useArtifacts(projectId, null, explanation?.id);
  const [autoPlayAudio, setAutoPlayAudio] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [error, setError] = useState(null);
  const containerRef = useRef(null);

  // Get current version
  const currentVersion = explanation?.versions?.find(v => v.is_active);
  
  useEffect(() => {
    const isCompleted = currentVersion?.explanation?.status === 'completed';
    const isError = currentVersion?.explanation?.status === 'error';
    
    if (isRegenerating) {
      if (isCompleted) {
        setIsRegenerating(false);
        setShowSuccessPopup(true);
        setAutoPlayAudio(true);
        const timer = setTimeout(() => setShowSuccessPopup(false), 3000);

        // Scroll to top on completion
        if (containerRef.current) {
          containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }

        return () => clearTimeout(timer);
      }
      
      if (isError) {
        setIsRegenerating(false);
        setError(currentVersion?.explanation?.error || 'Failed to generate explanation');
        const timer = setTimeout(() => setError(null), 3000);
        return () => clearTimeout(timer);
      }
    }
  }, [currentVersion?.explanation?.status, isRegenerating]);

  // Regenerate handler
  const handleRegenerate = async () => {
    try {
      setError(null); // Clear any existing errors
      setIsRegenerating(true);
      await generateExplanation(explanation.id, explanation.conversation_id, true);
    } catch (error) {
      console.error('Failed to regenerate explanation:', error);
      setIsRegenerating(false);
      setError('Failed to regenerate explanation');
      const timer = setTimeout(() => setError(null), 3000);
      return () => clearTimeout(timer);
    }
  };

  // Format timestamp helper
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return null;
    return new Date(timestamp * 1000).toLocaleString(undefined, {
      dateStyle: 'medium',
      timeStyle: 'short'
    });
  };

  const formatScript = (text) => {
    if (!text) return null;
    const parts = text.split(/\n(?=[\w\s]+:)/g);
    return parts.map((part, index) => {
      const match = part.match(/^([\w\s]+):(.*)$/s);
      if (!match) return <div key={index} className="text-gray-400 whitespace-pre-wrap text-xs leading-relaxed">{part}</div>;
      
      const [, speaker, content] = match;
      
      const agentDetails = currentVersion.explanation?.participants?.find(
        a => a.name === speaker.trim()
      );
      
      return (
        <div key={index} className="mb-2">
          <div className="flex flex-col gap-1">
            <div className="flex-shrink-0 bg-gray-700/50 rounded px-1.5 py-0.5 w-fit">
              <span className="text-blue-400 font-medium text-xs whitespace-nowrap">{speaker}</span>
              {agentDetails && (
                <span className="text-xs text-gray-400 block -mt-0.5 text-[10px]">
                  {agentDetails.role}
                </span>
              )}
            </div>
            <div className="text-gray-400 whitespace-pre-wrap text-xs leading-relaxed">
              {content.trimStart()}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div 
        ref={containerRef}
        className="bg-gray-800/95 backdrop-blur-sm rounded-md p-8 shadow-md max-h-[80vh] overflow-y-auto"
      >
        {/* Header Section with Regenerate Button */}
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-3">
            <div className="bg-blue-500/10 p-2 rounded-xl">
              <Headphones size={20} className="text-blue-400" />
            </div>
            <div>
              <h3 className="text-base font-semibold text-gray-100">Explanation Podcast</h3>
              {currentVersion?.explanation?.created_at && (
                <p className="text-xs text-gray-400 mt-0.5">
                  Generated {formatTimestamp(currentVersion.explanation.created_at)}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2">
            {/* Regenerate Button */}
            <button
              onClick={handleRegenerate}
              disabled={isRegenerating || explanationStatus === 'pending'}
              className={`text-gray-400 hover:text-gray-200 transition-colors p-2 rounded-md hover:bg-gray-700/50 disabled:opacity-50 disabled:cursor-not-allowed ${
                (isRegenerating || explanationStatus === 'pending') ? 'animate-pulse' : ''
              }`}
              title="Regenerate explanation"
            >
              <RefreshCw 
                size={16} 
                className={`${
                  (isRegenerating || explanationStatus === 'pending') ? 'animate-spin' : ''
                }`}
              />
            </button>
            {/* Close Button */}
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-200 transition-colors p-2 rounded-md hover:bg-gray-700/50"
              title="Close explanation"
            >
              <X size={16} />
            </button>
          </div>
        </div>

        {/* Error State */}
        {currentVersion?.explanation?.status === 'error' ? (
          <div className="flex items-center gap-3">
            <div className="bg-red-500/10 p-2 rounded-xl">
              <AlertCircle size={20} className="text-red-400" />
            </div>
            <div>
              <h3 className="text-base font-semibold text-gray-100">Explanation Failed</h3>
              <p className="text-sm text-red-400 mt-1">{currentVersion.explanation.error}</p>
            </div>
          </div>
        ) : (
          <>
            {/* Status Messages */}
            {(explanationStatus === 'pending' || isRegenerating) && (
              <div className="flex items-center gap-2 mb-6 p-3 bg-yellow-500/10 rounded-xl text-yellow-300 text-sm">
                <Loader2 className="animate-spin" size={16} />
                <span>
                  {isRegenerating ? 'Regenerating explanation...' : 'Generating explanation for the latest version...'}
                </span>
              </div>
            )}

            {/* Audio Player */}
            {currentVersion?.explanation?.url && (
              <div className="mb-6 min-h-[80px]">
                <div className="bg-gray-900/50 p-4 rounded-xl">
                  <audio 
                    controls 
                    className="w-full max-w-full"
                    src={currentVersion.explanation.url}
                    preload="metadata"
                    autoPlay={autoPlayAudio}
                  >
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>
            )}
            
            {/* Transcript Section */}
            {currentVersion?.explanation?.script && (
              <div className="max-h-[calc(60vh-120px)]">
                <h4 className="text-xs font-medium text-gray-300 mb-3">Transcript</h4>
                <div className="prose prose-sm prose-invert overflow-y-auto px-4 custom-scrollbar">
                  <div className="space-y-4">
                    {formatScript(currentVersion.explanation.script)}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {/* Success Popup */}
      {showSuccessPopup && (
        <StatusPopup
          message={isRegenerating ? "Explanation regenerated successfully!" : "Explanation generated successfully!"}
          onClose={() => setShowSuccessPopup(false)}
          type="success"
        />
      )}

      {error && (
        <StatusPopup 
          message={error} 
          onClose={() => setError(null)} 
          type="error"
        />
      )}
    </>
  );
};

export default PodcastExplanation; 