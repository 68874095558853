import React, { useState, useEffect } from 'react';
import { AlertCircle, X, Check } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Button from './ui/Button';

const StatusPopup = ({ message, onClose, type = 'error' }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      setTimeout(onClose, 300);
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  if (!isVisible) return null;

  const cleanMessage = message
    .replace(/^Error:?\s*/i, '')
    .replace(/API request:\s*/i, '')
    .replace(/Request failed with status code \d+:\s*/i, '')
    .trim();

  const displayMessage = type === 'error' ? `Error: ${cleanMessage}` : cleanMessage;

  return (
    <AnimatePresence>
      <motion.div 
        className="fixed bottom-4 right-4 max-w-md w-full z-50 pointer-events-none"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
      >
        <motion.div 
          className="bg-gray-800/90 border-l-4 border-red-500 shadow-lg rounded-lg backdrop-blur-sm pointer-events-auto"
          initial={{ x: 20 }}
          animate={{ x: 0 }}
        >
          <div className="flex items-start p-4 space-x-3">
            <div className="flex-shrink-0">
              {type === 'error' ? (
                <AlertCircle className="h-5 w-5 text-red-500" />
              ) : (
                <Check className="h-5 w-5 text-green-500" />
              )}
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-200 break-words">
                {displayMessage}
              </p>
            </div>
            <div className="flex-shrink-0 ml-4">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  setIsVisible(false);
                  setTimeout(onClose, 300);
                }}
                icon={<X className="h-4 w-4" />}
                className="hover:bg-gray-700/50"
              />
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default StatusPopup;