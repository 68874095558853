import React, { useState, useEffect } from 'react';
import { Plus, Trash2, Save, Loader } from 'lucide-react';
import StatusPopup from './StatusPopup';
import ConfirmationDialog from './ConfirmationDialog';
import Modal from './ui/Modal';
import Button from './ui/Button';
import LoadingButton from './ui/LoadingButton';
import ProjectForm from './ProjectForm';

const adjustTextareaHeight = (element) => {
  element.style.height = 'auto';
  element.style.height = `${element.scrollHeight}px`;
};

const ProjectEditDialog = ({ 
  isOpen, 
  onClose, 
  project,
  updateProject,
  deleteProject,
  isNewProject,
  isLoading,
  createProject,
}) => {
  const [projectData, setProjectData] = useState({
    name: '',
    objective: '',
    keyResults: [],
    metrics: [],
    outputFormat: 'Auto'
  });
  const [error, setError] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeletingProject, setIsDeletingProject] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (project) {
      setProjectData({
        name: project.name || '',
        objective: project.objective || '',
        keyResults: project.krs?.map(kr => ({ description: kr })) || [],
        metrics: project.metrics || [],
        outputFormat: project.output_format || 'Auto'
      });
    }
  }, [project]);

  useEffect(() => {
    if (!isOpen) {
      setIsEditing(false);
    }
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isNewProject && createProject) {
        await createProject(projectData);
      } else {
        await updateProject(project.id, projectData);
      }
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteClick = async () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setShowDeleteConfirmation(false);
      setIsDeletingProject(true);
      await deleteProject(project.id);
      onClose();
    } catch (error) {
      setError(error.message);
      setIsDeletingProject(false);
    }
  };

  const handleClose = () => {
    setIsEditing(false);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={isNewProject ? 'New Project' : 'Edit Project'}
      maxWidth="xl"
      disabled={isDeletingProject || isLoading}
    >
      <form onSubmit={handleSubmit} className="flex flex-col max-h-[600px]">
        <div className="flex-1 overflow-y-auto px-6 py-4">
          <ProjectForm 
            projectData={projectData}
            setProjectData={setProjectData}
            readOnly={false}
          />
        </div>

        <div className="flex justify-between items-center px-6 py-4 border-t border-gray-700/50 bg-gray-800/30">
          {!isNewProject && (
            <LoadingButton
              variant="danger"
              onClick={handleDeleteClick}
              isLoading={isDeletingProject}
              loadingText="Deleting..."
              disabled={isLoading || isDeletingProject}
              icon={<Trash2 size={20} />}
            >
              Delete
            </LoadingButton>
          )}
          <div className="flex gap-3 ml-auto">
            <Button
              variant="secondary"
              onClick={onClose}
              disabled={isDeletingProject}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              loadingText={isNewProject ? "Creating..." : "Saving..."}
              disabled={isLoading}
              icon={!isLoading && <Save size={20} />}
            >
              {isNewProject ? 'Create' : 'Save'}
            </LoadingButton>
          </div>
        </div>
      </form>

      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete Project"
          message="Are you sure you want to delete this project? This action cannot be undone."
          onConfirm={handleConfirmDelete}
          onCancel={() => setShowDeleteConfirmation(false)}
          confirmLabel="Delete"
          confirmStyle="danger"
        />
      )}

      {error && (
        <StatusPopup message={error} onClose={() => setError(null)} />
      )}
    </Modal>
  );
};

export default ProjectEditDialog; 