import React, { useState, useEffect, useRef } from 'react';
import { Headphones, Star, Loader2, Lightbulb } from 'lucide-react';

const ArtifactActionsSelect = ({ 
  onExplain, 
  onEvaluate,
  isGeneratingExplanation,
  isEvaluating,
  showExplanation,
  showEvaluation,
  isSmallScreen,
  hasExplanation,
  hasEvaluation
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }
  }, [isOpen]);

  const handleAction = (action) => {
    setIsOpen(false);
    if (action === 'explain') {
      onExplain();
    } else if (action === 'evaluate') {
      onEvaluate();
    }
  };

  const getButtonIcon = () => {
    if (isGeneratingExplanation || isEvaluating) {
      return <Loader2 size={14} className="animate-spin" />;
    }
    if (showExplanation) {
      return <Headphones size={14} className="text-blue-400" />;
    }
    if (showEvaluation) {
      return <Star size={14} className="text-yellow-400" />;
    }
    return <Lightbulb size={14} />;
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        disabled={isGeneratingExplanation || isEvaluating}
        className={`flex items-center justify-center text-gray-400 hover:text-gray-200 
          transition-colors p-1.5 rounded-md hover:bg-gray-600/50 w-7 h-7
          ${showExplanation ? 'text-blue-400 bg-gray-700/30' : ''}
          ${showEvaluation ? 'text-yellow-400 bg-gray-700/30' : ''}
          ${(isGeneratingExplanation || isEvaluating) ? 'opacity-50' : ''}`}
        title="Generate insights"
      >
        {getButtonIcon()}
      </button>

      {isOpen && (
        <div className="absolute left-0 bottom-full mb-1 bg-gray-800/95 backdrop-blur-sm rounded-lg shadow-lg overflow-hidden z-50 min-w-[180px]">
          <div className="py-1">
            <button
              onClick={() => handleAction('explain')}
              className={`w-full px-3 py-1.5 text-left text-xs flex items-center gap-2
                ${showExplanation ? 'text-blue-400 bg-gray-700/30' : 'text-gray-200'} 
                hover:bg-gray-700/30 transition-colors`}
            >
              <Headphones size={14} />
              <span>Explain</span>
            </button>
            <button
              onClick={() => handleAction('evaluate')}
              className={`w-full px-3 py-1.5 text-left text-xs flex items-center gap-2
                ${showEvaluation ? 'text-yellow-400 bg-gray-700/30' : 'text-gray-200'} 
                hover:bg-gray-700/30 transition-colors`}
            >
              <Star size={14} />
              <span>Evaluate</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArtifactActionsSelect; 