import React, { useState } from 'react';
import { MessageSquare, Trash2, Loader2, Filter } from 'lucide-react';
import Button from './ui/Button';
import FilterBar from './FilterBar';

const RecentConversations = ({
  conversations,
  onSelect,
  onDelete,
  isDeletingThread,
  isSmallScreen,
  handleNewConversation,
  isCreatingNewThread,
  isLoadingConversations
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredConversations = conversations?.filter(conversation => 
    conversation?.title?.toLowerCase().includes(searchTerm?.toLowerCase() ?? '') ?? false
  );

  return (
    <div className="w-full pr-2 mt-8">
      <div className="flex flex-col w-full px-4 mb-2">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center gap-1">
            <button 
              onClick={() => setShowFilters(!showFilters)}
              aria-label="Toggle conversation filters"
              aria-expanded={showFilters}
              className="text-gray-400 hover:text-gray-200 p-1 hover:bg-gray-800/30 rounded-lg transition-colors"
            >
              <Filter className={`h-3.5 w-3.5 ${showFilters ? 'text-gray-200' : 'text-gray-400'}`} />
            </button>
            <p className="text-sm font-medium text-gray-400">Recent Conversations</p>
          </div>
          <Button
            variant="ghost"
            size="sm"
            onClick={handleNewConversation}
            disabled={isCreatingNewThread}
            className="text-gray-400 hover:text-gray-200 flex items-center gap-2 px-3 
              bg-gray-800/30 hover:bg-gray-800/50 backdrop-blur-sm rounded-lg"
          >
            {isCreatingNewThread ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <MessageSquare className="h-4 w-4" />
            )}
            <span className={`text-sm ${isSmallScreen ? 'hidden' : 'inline'}`}>
              New Chat
            </span>
          </Button>
        </div>

        <FilterBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          itemType="conversations"
          isSmallScreen={isSmallScreen}
          isExpanded={showFilters}
          showShared={false}
          setShowShared={() => {}}
          showToggle={false}
        />
      </div>

      <div className="bg-gray-700 rounded-lg px-4">
        {isLoadingConversations ? (
          <div className="flex flex-col items-center justify-center py-8 gap-2">
            <Loader2 className="h-5 w-5 animate-spin text-gray-400" />
            <span className="text-sm text-gray-400">Loading conversations...</span>
          </div>
        ) : filteredConversations?.length ? (
          <div className={`w-full flex flex-col gap-1.5 ${isSmallScreen ? 'max-h-[230px]' : 'max-h-[138px]'} 
            overflow-y-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-transparent`}>
            {filteredConversations.map(conversation => (
              <div 
                key={conversation.id}
                onClick={() => onSelect(conversation.projectId, conversation.id)}
                className="w-full bg-gray-800/30 hover:bg-gray-800/50 backdrop-blur-sm 
                  rounded-lg py-2.5 px-3 cursor-pointer flex items-center gap-2.5 group transition-colors"
              >
                <MessageSquare className="h-3.5 w-3.5 text-gray-400" />
                <span className="text-gray-200 flex-grow truncate text-sm mr-4">
                  {conversation.title}
                  {conversation.is_background && (
                    <span className="ml-2 text-xs text-blue-400">(Background)</span>
                  )}
                </span>
                {onDelete && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(conversation.id);
                    }}
                    className="opacity-0 group-hover:opacity-100 text-gray-400 
                      hover:text-gray-200 transition-opacity"
                  >
                    <Trash2 className="h-3.5 w-3.5" />
                  </button>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8 text-gray-400 text-sm">
            {searchTerm ? 'No matching conversations found' : 'No conversations yet'}
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentConversations;