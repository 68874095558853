import React, { useState, useRef } from 'react';
import { Loader, Trash2, Check, X } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import StatusPopup from './StatusPopup';
import ConfirmationDialog from './ConfirmationDialog';

const Threads = ({
  threads,
  threadId,
  handleThreadSelect,
  loadMoreThreads,
  moreThreadsAvailable,
  deleteThread,
  renameThread,
  isLoadingThreads,
  isRenamingThread,
  setIsCollapsed,
  isSmallScreen,
}) => {
  const [error, setError] = useState(null);
  const [isLoadingMoreThreads, setIsLoadingMoreThreads] = useState(false);
  const [isDeletingThread, setIsDeletingThread] = useState(false);
  const [isSelectingThread, setIsSelectingThread] = useState(false);
  const [editingThreadId, setEditingThreadId] = useState(null);
  const [editingTitle, setEditingTitle] = useState('');
  const editInputRef = useRef(null);
  const [threadToDelete, setThreadToDelete] = useState(null);

  const handleDeleteThreadClick = async (threadId) => {
    setThreadToDelete(threadId);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsDeletingThread(true);
      await deleteThread(threadToDelete);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDeletingThread(false);
      setThreadToDelete(null);
    }
  };

  const handleLoadMoreThreads = async () => {
    try {
      setIsLoadingMoreThreads(true);
      await loadMoreThreads();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoadingMoreThreads(false);
    }
  };

  const handleThreadSelectWrapper = async (selectedThreadId) => {
    if (isSelectingThread) return;

    setIsSelectingThread(true);
    try {
      await handleThreadSelect(selectedThreadId);
      if (isSmallScreen) {
        setIsCollapsed(true);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSelectingThread(false);
    }
  };

  const handleDoubleClick = (thread) => {
    setEditingThreadId(thread.id);
    setEditingTitle(thread.title);
    setTimeout(() => {
      if (editInputRef.current) {
        editInputRef.current.focus();
        editInputRef.current.style.height = 'auto';
        editInputRef.current.style.height = `${editInputRef.current.scrollHeight}px`;
      }
    }, 0);
  };

  const handleEditSubmit = async (e, thread) => {
    e.preventDefault();
    if (editingTitle.trim() && editingTitle !== thread.title) {
      try {
        await renameThread(editingThreadId, editingTitle.trim());
      } catch (error) {
        setError(error.message);
      }
    }
    setEditingThreadId(null);
    setEditingTitle('');
  };

  const handleEditCancel = () => {
    setEditingThreadId(null);
    setEditingTitle('');
  };

  return (
    <div className="space-y-2 bg-gray-900/50 p-3 rounded-md shadow-lg">
      {isLoadingThreads ? (
        <div className="flex justify-center items-center py-4">
          <Loader size={24} className="animate-spin text-gray-400" />
        </div>
      ) : (
        <>
          {threads && threads.map((thread) => (
            <div key={thread.id} className="relative group">
              {editingThreadId === thread.id ? (
                <form onSubmit={(e) => handleEditSubmit(e, thread)} className="w-full flex gap-2">
                  <textarea
                    ref={editInputRef}
                    value={editingTitle}
                    onChange={(e) => {
                      setEditingTitle(e.target.value);
                      e.target.style.height = 'auto';
                      e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                    onBlur={handleEditCancel}
                    onKeyDown={(e) => {
                      if (e.key === 'Escape') handleEditCancel();
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleEditSubmit(e, thread);
                      }
                    }}
                    className="flex-1 px-3 py-2 border border-blue-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-200 overflow-hidden resize-none bg-gray-700 text-gray-200"
                    disabled={isRenamingThread}
                    style={{ height: 'auto' }}
                  />
                </form>
              ) : (
                <>
                  <button
                    onClick={() => handleThreadSelectWrapper(thread.id)}
                    onDoubleClick={() => handleDoubleClick(thread)}
                    className={`w-full text-left px-3 py-2 rounded-md transition-all duration-200 ${
                      threadId === thread.id 
                        ? 'bg-blue-600/20 text-gray-100 hover:bg-blue-600/30 ring-1 ring-blue-500/40'
                        : 'bg-gray-800/40 hover:bg-gray-700/40 text-gray-300 hover:text-gray-200'
                    }`}
                  >
                    <ReactMarkdown className="text-sm font-medium pr-8 text-gray-300">{thread.title}</ReactMarkdown>
                  </button>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteThreadClick(thread.id);
                    }}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 cursor-pointer"
                  >
                    {isDeletingThread ? (
                      <Loader size={16} className="animate-spin text-red-400" />
                    ) : (
                      <Trash2 size={16} className="text-red-400 hover:text-red-300 transition-colors duration-150" />
                    )}
                  </div>
                </>
              )}
            </div>
          ))}
          {moreThreadsAvailable && (
            <button
              onClick={handleLoadMoreThreads}
              className="w-full p-2 bg-blue-600/90 text-white rounded-md 
                hover:bg-blue-500/90 transition duration-200 
                disabled:opacity-50 disabled:cursor-not-allowed 
                text-sm shadow-lg hover:shadow-blue-500/20"
              disabled={isLoadingMoreThreads}
            >
              {isLoadingMoreThreads ? <Loader size={16} className="animate-spin mx-auto" /> : 'Load More'}
            </button>
          )}
        </>
      )}

      {threadToDelete && (
        <ConfirmationDialog
          title="Delete Conversation"
          message="Are you sure you want to delete this conversation? This action cannot be undone."
          onConfirm={handleConfirmDelete}
          onCancel={() => setThreadToDelete(null)}
          confirmText="Delete"
          isLoading={isDeletingThread}
        />
      )}

      {error && (
        <StatusPopup message={error} onClose={() => setError(null)} />
      )}
    </div>
  );
};

export default Threads; 