import React, { useState, useRef, useEffect } from 'react';
import { X, Star, AlertCircle, Loader2, RefreshCw } from 'lucide-react';
import StatusPopup from './StatusPopup';

const ArtifactEvaluation = ({ artifact, onClose, evaluateArtifact }) => {
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [error, setError] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const containerRef = useRef(null);

  // Get current version
  const currentVersion = artifact?.versions?.find(v => v.is_active);

  // Add regenerate handler
  const handleRegenerate = async () => {
    try {
      setError(null); // Clear any existing errors
      setIsRegenerating(true);
      await evaluateArtifact(artifact.id);
    } catch (error) {
      console.error('Failed to regenerate evaluation:', error);
      setIsRegenerating(false);
      setError('Failed to regenerate evaluation');
      const timer = setTimeout(() => setError(null), 3000);
      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    const isCompleted = currentVersion?.evaluation?.status === 'completed';
    const isError = currentVersion?.evaluation?.status === 'error';
    
    if (isRegenerating) {
      if (isCompleted) {
        setIsRegenerating(false);
        setShowSuccessPopup(true);
        const timer = setTimeout(() => setShowSuccessPopup(false), 3000);

        // Scroll to top on completion
        if (containerRef.current) {
          containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
        return () => clearTimeout(timer);
      }
      
      if (isError) {
        setIsRegenerating(false);
        setError(currentVersion?.evaluation?.error || 'Failed to generate evaluation');
        const timer = setTimeout(() => setError(null), 3000);
        return () => clearTimeout(timer);
      }
    }
  }, [currentVersion?.evaluation?.status, isRegenerating]);

  // Format timestamp helper
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return null;
    return new Date(timestamp * 1000).toLocaleString(undefined, {
      dateStyle: 'medium',
      timeStyle: 'short'
    });
  };

  // Early returns
  if (!artifact || !currentVersion) return null;
  if (!currentVersion.is_active) return null;
  if (!currentVersion.evaluation) return null;

  return (
    <>
      <div ref={containerRef} className="bg-gray-800/95 backdrop-blur-sm rounded-md p-8 shadow-md">
        {/* Header Section */}
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-3">
            <div className="bg-yellow-500/10 p-2 rounded-xl">
              <Star size={20} className="text-yellow-400" />
            </div>
            <div>
              <h3 className="text-base font-semibold text-gray-100">Artifact Evaluation</h3>
              {currentVersion?.evaluation?.created_at && (
                <p className="text-xs text-gray-400 mt-0.5">
                  Generated {formatTimestamp(currentVersion.evaluation.created_at)}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2">
            {isRegenerating ? (
              <Loader2 size={16} className="text-gray-400 animate-spin" />
            ) : (
              <button
                onClick={handleRegenerate}
                className="text-gray-400 hover:text-gray-200 transition-colors p-2 rounded-md hover:bg-gray-700/50"
                title="Re-evaluate"
              >
                <RefreshCw size={16} />
              </button>
            )}
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-200 transition-colors p-2 rounded-md hover:bg-gray-700/50"
              title="Close evaluation"
            >
              <X size={16} />
            </button>
          </div>
        </div>

        {/* Score Section */}
        <div className="mb-6">
          <div className="bg-gray-900/50 p-4 rounded-xl">
            <div className="flex items-center gap-2">
              <span className="text-2xl font-bold text-yellow-400">
                {currentVersion.evaluation.score}
              </span>
              <span className="text-gray-400 text-sm">/10</span>
            </div>
            {currentVersion.evaluation.metric?.name && (
              <div className="text-xs text-gray-400 mt-1">
                {currentVersion.evaluation.metric.name}
              </div>
            )}
          </div>
        </div>

        {/* Evaluation Scale */}
        {currentVersion.evaluation.metric?.scale && (
          <div className="mb-6">
            <h4 className="text-xs font-medium text-gray-300 mb-3">Evaluation Scale</h4>
            <div className="bg-gray-900/50 p-4 rounded-xl">
              <div className="space-y-2">
                {Object.entries(currentVersion.evaluation.metric.scale)
                  .sort((a, b) => Number(b[0]) - Number(a[0]))
                  .map(([score, description]) => (
                    <div 
                      key={score} 
                      className={`flex items-start gap-2 text-xs ${
                        Number(score) === currentVersion.evaluation.score 
                          ? 'text-yellow-400' 
                          : 'text-gray-400'
                      }`}
                    >
                      <span className="font-medium min-w-[24px]">{score}/10:</span>
                      <span>{description}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}

        {/* Rationale Section */}
        {currentVersion.evaluation.rationale && (
          <div>
            <h4 className="text-xs font-medium text-gray-300 mb-3">Evaluation Rationale</h4>
            <div className="prose prose-sm prose-invert">
              <div className="text-gray-400 text-xs leading-relaxed whitespace-pre-wrap">
                {currentVersion.evaluation.rationale}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Success Popup */}
      {showSuccessPopup && (
        <StatusPopup
          message="Evaluation regenerated successfully!"
          onClose={() => setShowSuccessPopup(false)}
          type="success"
        />
      )}

      {/* Error Popup */}
      {error && (
        <StatusPopup 
          message={error} 
          onClose={() => setError(null)} 
          type="error"
        />
      )}
    </>
  );
};

export default ArtifactEvaluation; 