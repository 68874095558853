import React, { useState, useEffect } from 'react';
import { X, Copy, Check, Loader2, ExternalLink, Maximize2, Minimize2, History, ChevronLeft, ChevronRight, RefreshCw } from 'lucide-react';
import SharedMarkdown from '../utils/SharedMarkdown';
import { getArtifactIcon } from '../utils/iconUtils';
import { formatNameWithLink } from '../utils/urlUtils';
import copy from 'clipboard-copy';
import { truncateString, getTooltipText } from '../utils/stringUtils';
import StatusPopup from './StatusPopup';
import useArtifacts from '../hooks/useArtifacts';
import { FileViewer, ViewerControls, JsonView } from './FileViewers';
import WhiteboardViewer from './WhiteboardViewer';
import ArtifactNavigation from './ArtifactNavigation';
import { getToolConfig } from '../utils/toolConfigs';
import ToolOutputViewer from './ToolOutputViewer';
import useApi from '../hooks/useApi';
import PodcastExplanation from './PodcastExplanation';
import ArtifactEvaluation from './ArtifactEvaluation';
import ArtifactActionsSelect from './ArtifactActionsSelect';

const Workbench = ({ 
  selectedArtifactId,
  setSelectedArtifactId, 
  selectedToolOutput,
  setSelectedToolOutput, 
  projectId, 
  threadId, 
  isSmallScreen, 
  onClose,
  isFullscreen,
  setIsFullscreen,
  updateProject,
  projectData,
  agentData,
  updateAgent,
  getAgentWithVersions,
  models,
  removeDataSource,
  addDataSource,
  getAgentData,
  onRefreshArtifact,
  isLoadingArtifacts,
  refreshSingleArtifact
}) => {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(null);
  const [viewerType, setViewerType] = useState('google');
  const [darkMode, setDarkMode] = useState(false);
  const [showVersions, setShowVersions] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [fileViewerKey, setFileViewerKey] = useState(Date.now());
  const [isGeneratingExplanation, setIsGeneratingExplanation] = useState(false);
  const [explanationArtifact, setExplanationArtifact] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [showEvaluation, setShowEvaluation] = useState(false);
  const { api } = useApi();

  const { 
    navigation, 
    handlePrevious, 
    handleNext, 
    currentArtifact, 
    isLoading,
    refreshArtifacts,
    explanationStatus,
    evaluationStatus,
    evaluateArtifact
  } = useArtifacts(
    projectId, 
    threadId, 
    selectedArtifactId
  );

  useEffect(() => {
    if (selectedArtifactId !== null) {
      setSelectedVersion(null);
    }
  }, [selectedArtifactId]);

  useEffect(() => {
    if (selectedToolOutput) {
      setSelectedArtifactId(null);
    }
  }, [selectedToolOutput, setSelectedArtifactId]);

  useEffect(() => {
    if (selectedArtifactId) {
      setSelectedToolOutput(null);
    }
  }, [selectedArtifactId, setSelectedToolOutput]);

  useEffect(() => {
    if (onRefreshArtifact && refreshArtifacts) {
      onRefreshArtifact(refreshArtifacts);
    }
  }, [refreshArtifacts, onRefreshArtifact]);

  useEffect(() => {
    if (explanationStatus === 'completed') {
      setShowExplanation(true);
      refreshSingleArtifact(selectedArtifactId);
    } else if (explanationStatus === 'error') {
      setError('Failed to generate explanation');
      setIsGeneratingExplanation(false);
    }
  }, [explanationStatus, refreshSingleArtifact, selectedArtifactId]);

  useEffect(() => {
    if (evaluationStatus === 'completed') {
      setShowEvaluation(true);
      refreshSingleArtifact(selectedArtifactId);
    } else if (evaluationStatus === 'error') {
      setError('Failed to generate evaluation');
      setIsEvaluating(false);
    }
  }, [evaluationStatus, refreshSingleArtifact, selectedArtifactId]);

  if ((isLoadingArtifacts && isLoading) && !currentArtifact) {
    return (
      <div className="flex items-center justify-center h-full text-gray-400">
        <Loader2 className="animate-spin" size={16} />
      </div>
    );
  }

  if (selectedArtifactId && !currentArtifact) {
    return (
      <div className="flex items-center justify-center h-full text-gray-400 p-4">
        <Loader2 className="animate-spin" size={16} />
      </div>
    );
  }

  const handleCopy = async () => {
    try {
      let textToCopy;
      if (selectedToolOutput) {
        const { message, toolOutputIndex } = selectedToolOutput;
        const output = message.tool_outputs[toolOutputIndex];
        textToCopy = output.output;
      } else if (currentArtifact) {
        const version = selectedVersion || currentArtifact.active_version;
        
        switch (currentArtifact.type) {
          case 'document':
          case 'presentation':
            if (version?.url) {
              textToCopy = version.url;
            } else {
              throw new Error('No URL available');
            }
            break;
          default:
            textToCopy = currentArtifact.markdown || 
                         JSON.stringify(version?.structured_output || currentArtifact.structured_output, null, 2);
        }
      }

      if (!textToCopy) {
        throw new Error('No content available to copy');
      }

      await copy(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      setError('Failed to copy content to clipboard');
    }
  };

  const renderToolOutput = () => {
    if (!selectedToolOutput) return null;
    
    return (
      <ToolOutputViewer
        output={selectedToolOutput.message.tool_outputs[selectedToolOutput.toolOutputIndex]}
        projectData={projectData}
        updateProject={updateProject}
        agentData={agentData}
        updateAgent={updateAgent}
        getAgentWithVersions={getAgentWithVersions}
        models={models}
        removeDataSource={removeDataSource}
        addDataSource={addDataSource}
        getAgentData={getAgentData}
      />
    );
  };

  const renderVersionHistory = () => {
    if (!currentArtifact?.versions?.length) return null;

    const versions = currentArtifact.versions;
    const activeVersion = versions.find(v => v.is_active) || versions[0];
    const currentVersion = selectedVersion || activeVersion;
    const currentIndex = versions.findIndex(v => v.created_at === currentVersion.created_at);

    return (
      <div className="absolute bottom-0 left-0 right-0 bg-gray-700/95 border-t border-gray-600/30">
        <div className="flex flex-col p-2">
          {/* Navigation and Version Info */}
          <div className="flex items-center justify-between mb-2">
            <button
              onClick={() => currentIndex > 0 && setSelectedVersion(versions[currentIndex - 1])}
              disabled={currentIndex === 0}
              className={`p-1 rounded ${currentIndex === 0 ? 'text-gray-500' : 'text-gray-300 hover:text-white hover:bg-gray-700'}`}
              title={currentIndex === 0 ? "No previous version" : "View previous version"}
            >
              <ChevronLeft size={16} />
            </button>

            <div className="flex-1 mx-4 text-center">
              <div className="text-xs text-gray-200">
                Version {currentIndex + 1} of {versions.length}
                {currentVersion.is_active && <span className="ml-2 text-indigo-400">(Active)</span>}
              </div>
              <div className="text-xs text-gray-400">
                {new Date(currentVersion.created_at * 1000).toLocaleString()}
              </div>
            </div>

            <div className="flex items-center gap-2">
              <button
                onClick={() => currentIndex < versions.length - 1 && setSelectedVersion(versions[currentIndex + 1])}
                disabled={currentIndex === versions.length - 1}
                className={`p-1 rounded ${currentIndex === versions.length - 1 ? 'text-gray-500' : 'text-gray-300 hover:text-white hover:bg-gray-700'}`}
                title={currentIndex === versions.length - 1 ? "No next version" : "View next version"}
              >
                <ChevronRight size={16} />
              </button>
              <button
                onClick={() => {
                  setShowVersions(false);
                  setSelectedVersion(activeVersion);
                }}
                className="p-1 rounded text-gray-400 hover:text-white hover:bg-gray-700"
                title="Close version history"
              >
                <X size={16} />
              </button>
            </div>
          </div>

          {/* Change Summary */}
          {currentVersion.change_summary && (
            <div className="px-4 py-2 bg-gray-800/50 rounded text-[11px] text-gray-300 max-h-24 overflow-y-auto">
              <div className="whitespace-pre-wrap leading-relaxed italic">
                {currentVersion.change_summary}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderArtifact = () => {
    if (!currentArtifact) return null;

    const content = selectedVersion || 
                   currentArtifact.versions?.find(v => v.is_active) || 
                   currentArtifact;

    if (!content.url && !currentArtifact.markdown && !content.structured_output) {
      return (
        <div className="flex items-center justify-center h-full text-gray-400">
          <span>No content available</span>
        </div>
      );
    }

    switch (currentArtifact.type) {
      case 'document':
      case 'presentation':
        if (content.url) {
          return (
            <div className="flex flex-col h-full">
              <FileViewer
                key={fileViewerKey}
                url={content.url}
                fileType={currentArtifact.type}
                viewerType={viewerType}
                darkMode={darkMode}
              />
              {currentArtifact.description && (
                <div className="mt-2 p-2 bg-gray-700 rounded-md">
                  <p className="text-xs text-gray-300">{currentArtifact.description}</p>
                </div>
              )}
            </div>
          );
        }
        break;

      case 'whiteboard':
        return (
          <WhiteboardViewer 
            data={content.structured_output} 
          />
        );

      case 'plan':
      case 'evaluation':
        if (content.structured_output) {
          return <JsonView data={content.structured_output} />;
        }
        break;
    }

    if (currentArtifact.markdown) {
      return (
        <div className="prose prose-sm prose-invert max-w-none text-xs">
          <SharedMarkdown className="text-xs leading-relaxed" isWorkbench={true}>
            {currentArtifact.markdown}
          </SharedMarkdown>
        </div>
      );
    }
  };

  const getHeaderTitle = () => {
    if (selectedToolOutput) {
      if (selectedToolOutput.type === 'whiteboard') {
        return 'Whiteboard';
      }
      const { message, toolOutputIndex } = selectedToolOutput;
      return message.tool_outputs[toolOutputIndex].name;
    }
    return currentArtifact?.name || 'No title';
  };

  const getHeaderIcon = () => {
    if (selectedToolOutput) {
      const { message, toolOutputIndex } = selectedToolOutput;
      const toolName = message.tool_outputs[toolOutputIndex].name;
      const config = getToolConfig(toolName);
      const IconComponent = config.icon;
      return <IconComponent size={14} className={config.color} />;
    }
    return currentArtifact ? getArtifactIcon(currentArtifact.type) : null;
  };

  const renderContent = () => {
    if (selectedToolOutput?.type === 'whiteboard') {
      return (
        <WhiteboardViewer 
          projectId={projectId}
          conversationId={threadId}
        />
      );
    }

    if (selectedToolOutput) {
      return renderToolOutput();
    }

    if (currentArtifact) {
      return (
        <>
          {showExplanation && currentArtifact && (
            <PodcastExplanation 
              explanation={currentArtifact} 
              onClose={() => setShowExplanation(false)}
              projectId={projectId}
            />
          )}
          {showEvaluation && currentArtifact && (
            <ArtifactEvaluation
              artifact={currentArtifact}
              onClose={() => setShowEvaluation(false)}
              evaluateArtifact={evaluateArtifact}
            />
          )}
          {renderArtifact()}
        </>
      );
    }

    return null;
  };

  const handleReloadViewer = () => {
    if (selectedArtifactId && refreshSingleArtifact) {
      refreshSingleArtifact(selectedArtifactId);
    }
    setFileViewerKey(Date.now());
  };

  const handleGenerateExplanation = async () => {
    if (!currentArtifact || !threadId) return;
    
    // Get the active version
    const activeVersion = currentArtifact.versions?.find(v => v.is_active) || currentArtifact.versions?.[0];
    
    // If explanation exists and is completed, just show it
    if (activeVersion?.explanation?.status === 'completed') {
        setShowExplanation(true);
        return;
    }
    
    try {
        setError(null); // Clear any existing errors
        setIsGeneratingExplanation(true);
        await api.post(
            `/artifacts/${projectId}/${currentArtifact.id}/explain`,
            null,
            { params: { conversation_id: threadId } }
        );
        
        refreshSingleArtifact(currentArtifact.id);
        
    } catch (error) {
        console.error('Error generating explanation:', error);
        setError('Failed to generate audio explanation');
        setIsGeneratingExplanation(false);
    }
  };

  const handleGenerateEvaluation = async () => {
    if (!currentArtifact || !threadId) return;
    
    // If evaluation exists and is completed, just show it
    if (currentArtifact.active_version?.evaluation?.status === 'completed') {
      setShowEvaluation(true);
      return;
    }
    
    try {
      setError(null); // Clear any existing errors
      setIsEvaluating(true);
      await evaluateArtifact(currentArtifact.id);
      
      refreshSingleArtifact(currentArtifact.id);
      
    } catch (error) {
      console.error('Error generating evaluation:', error);
      setError('Failed to generate evaluation');
      setIsEvaluating(false);
    }
  };

  return (
    <div className={`detail-panel flex flex-col h-full font-sans text-sm relative 
      ${isSmallScreen ? 'z-50' : 'z-40'}
      bg-gray-900/95 backdrop-blur-md ${isFullscreen ? 'fixed inset-0 rounded-none' : ''}`}>
      {/* Header - lighter color to match InputForm */}
      <div className="bg-gray-700/90 h-10 flex justify-between items-center px-3 
        border-b border-gray-600/30 rounded-t-lg sticky top-0 z-50">
        <h2 className="text-sm font-medium text-gray-200 flex items-center font-sans gap-2 min-w-0 mr-4">
          <span className="flex-shrink-0">{getHeaderIcon()}</span>
          <span className="truncate min-w-0" title={getHeaderTitle()}>{getHeaderTitle()}</span>
        </h2>
        <div className="flex items-center flex-shrink-0">
          <div className="flex">
            {selectedToolOutput?.message?.tool_outputs[selectedToolOutput.toolOutputIndex]?.name === 'whiteboard' && (
              <button 
                onClick={() => {
                  const currentOutput = selectedToolOutput;
                  setSelectedToolOutput(null);
                  setTimeout(() => setSelectedToolOutput(currentOutput), 0);
                }} 
                className="text-gray-400 hover:text-gray-200 transition-colors p-1.5
                  hover:bg-gray-600/50"
                title="Refresh whiteboard"
              >
                <RefreshCw size={16} />
              </button>
            )}
            <button 
              onClick={() => setIsFullscreen(!isFullscreen)} 
              className="text-gray-400 hover:text-gray-200 transition-colors p-1.5
                hover:bg-gray-600/50"
              title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
            >
              {isFullscreen ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
            </button>
            <button 
              onClick={onClose} 
              className="text-gray-400 hover:text-gray-200 transition-colors p-1.5
                hover:bg-gray-600/50"
              title="Close"
            >
              <X size={16} />
            </button>
          </div>
        </div>
      </div>
      
      {/* Content */}
      <div className="flex-1 min-h-0 overflow-hidden flex flex-col relative">
        <div className="flex-1 overflow-y-auto">
          <div className={`h-full ${selectedToolOutput || !currentArtifact?.url ? 'px-3' : ''}`}>
            {renderContent()}
          </div>
        </div>
        {showVersions && renderVersionHistory()}
      </div>

      {/* Footer */}
      <div className="bg-gray-700/90 h-10 flex justify-between items-center px-3 
        border-t border-gray-600/30 rounded-b-lg">
        {/* Left side - Navigation */}
        <div className="flex gap-2">
          {!selectedToolOutput && (
            <>
              <ArtifactNavigation
                onPrevious={handlePrevious}
                onNext={handleNext}
                navigation={navigation}
              />
              {currentArtifact?.url && (
                <button 
                  onClick={handleReloadViewer} 
                  className="text-gray-400 hover:text-gray-200 transition-colors p-1.5
                    hover:bg-gray-600/50 rounded-md"
                  title="Refresh document"
                >
                  <RefreshCw size={14} />
                </button>
              )}
            </>
          )}
        </div>
        
        {/* Right side - Actions */}
        <div className="flex items-center gap-2">
          {currentArtifact?.versions?.length > 1 && (
            <button 
              onClick={() => setShowVersions(!showVersions)} 
              className={`flex items-center gap-1.5 text-gray-400 hover:text-gray-200 
                transition-colors p-1.5 rounded-md hover:bg-gray-600/50 ${
                showVersions ? 'text-blue-400 bg-gray-700/30' : ''
              }`}
            >
              <History size={14} />
              <span className="text-xs">{currentArtifact.versions.length} revisions</span>
            </button>
          )}
          {currentArtifact && !selectedToolOutput && (
            <ArtifactActionsSelect
              onExplain={handleGenerateExplanation}
              onEvaluate={handleGenerateEvaluation}
              isGeneratingExplanation={isGeneratingExplanation}
              isEvaluating={isEvaluating}
              showExplanation={showExplanation}
              showEvaluation={showEvaluation}
              isSmallScreen={isSmallScreen}
              hasExplanation={currentArtifact.active_version?.explanation?.status === 'completed'}
              hasEvaluation={!!currentArtifact.active_version?.evaluation}
            />
          )}
          {currentArtifact?.url && (
            <ViewerControls
              viewerType={viewerType}
              setViewerType={setViewerType}
              darkMode={darkMode}
              setDarkMode={setDarkMode}
              onReload={handleReloadViewer}
            />
          )}
          <button
            onClick={handleCopy}
            className="text-gray-400 hover:text-gray-200 transition-colors p-1.5 rounded-md 
              hover:bg-gray-600/50"
          >
            {copied ? <Check size={14} className="text-green-500" /> : <Copy size={14} />}
          </button>
        </div>
      </div>

      {/* Version history panel */}
      {showVersions && (
        <div className="absolute bottom-10 left-0 right-0 bg-gray-900/95 
          border-t border-gray-700/30 shadow-lg backdrop-blur-md">
          <div className="flex items-center justify-between">
            {/* ... version history content ... */}
          </div>
        </div>
      )}

      {error && (
        <StatusPopup message={error} onClose={() => setError(null)} />
      )}
    </div>
  );
};

export default Workbench;
