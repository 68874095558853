import React from 'react';
import { BarChart3 } from 'lucide-react';
import useTokens from '../../hooks/useTokens';

const TokenDisplay = ({ onShowDetails }) => {
    const { usage, isLoading, formatNumber } = useTokens();

    if (isLoading || !usage || !usage.limits) {
        return null;
    }

    const remainingRequests = usage.limits.remaining_requests || 0;

    return (
        <button
            onClick={onShowDetails}
            className="text-xs text-gray-400 hover:text-gray-300 flex items-center gap-2 
                bg-gray-800/30 hover:bg-gray-800/50 backdrop-blur-sm rounded-lg px-3 py-1.5"
            title={`${formatNumber(remainingRequests)} requests remaining`}
        >
            <span className="hidden md:inline">{formatNumber(remainingRequests)} requests available</span>
            <span className="md:hidden">{formatNumber(remainingRequests)}</span>
            <BarChart3 className="h-3.5 w-3.5 text-gray-500" />
        </button>
    );
};

export default TokenDisplay; 