import React, { useState } from 'react';
import { FileText, Trash2, Download, ExternalLink } from 'lucide-react';

const ArtifactPanel = ({
  activeArtifacts = [],
  availableArtifacts = [],
  onRemoveArtifact,
  isLoadingArtifacts,
  setIsCollapsed,
  isSmallScreen,
  onArtifactClick,
  selectedProjectId,
  selectedThreadId
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filter and sort available artifacts - exclude active ones
  const filteredAvailableArtifacts = availableArtifacts
    .filter(artifact => 
      // Only show in available if not already in active
      !activeArtifacts.some(active => active.id === artifact.id) &&
      // Match search term if one exists
      (searchTerm === '' || artifact.name.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  // Sort active artifacts
  const sortedActiveArtifacts = [...activeArtifacts]
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const handleDownload = (artifact) => {
    window.open(artifact.url, '_blank');
  };

  const handleView = (artifact) => {
    window.open(artifact.view_url, '_blank');
  };

  const handleArtifactClick = (artifact, e) => {
    e.stopPropagation();
    onArtifactClick(artifact);
    if (isSmallScreen) {
      setIsCollapsed(true);
    }
  };

  const ArtifactItem = ({ artifact, isActive }) => {
    const artifactType = artifact.type?.toLowerCase() || 'unknown';
    const createdAt = artifact.created_at ? new Date(artifact.created_at * 1000) : new Date();
    
    return (
      <div
        key={artifact.id}
        onClick={(e) => handleArtifactClick(artifact, e)}
        className={`group relative flex flex-col px-2 py-1.5 rounded-md transition-all duration-200 
          hover:shadow-lg hover:shadow-gray-500/5 hover:translate-x-0.5 cursor-pointer
          ${isActive ? 'bg-gray-400/40 hover:bg-gray-400/60' : 'bg-gray-700/80 hover:bg-gray-600/80'}`}
      >
        <div className="flex-1 min-w-0">
          <div className="text-sm font-medium text-gray-100 truncate">
            {artifact.name}
          </div>
          <div className="text-xs text-gray-400 truncate">
            {artifactType} • {createdAt.toLocaleDateString()}
          </div>
        </div>
        <div className="absolute right-2 top-2 flex items-center gap-1.5">
          {artifact.versions?.[0]?.url && (
            <button
              className="p-1 rounded-md bg-gray-700/80 hover:bg-blue-500/80 
                transition-all duration-200 group-hover:shadow-md
                opacity-0 group-hover:opacity-100"
              onClick={(e) => {
                e.stopPropagation();
                handleDownload(artifact);
              }}
            >
              <Download size={16} className="text-gray-300 hover:text-white" />
            </button>
          )}
          {artifact.view_url && (
            <button
              className="p-1 rounded-md bg-gray-700/80 hover:bg-blue-500/80 
                transition-all duration-200 group-hover:shadow-md
                opacity-0 group-hover:opacity-100"
              onClick={(e) => {
                e.stopPropagation();
                handleView(artifact);
              }}
            >
              <ExternalLink size={16} className="text-gray-300 hover:text-white" />
            </button>
          )}
          {selectedThreadId && isActive && (
            <button
              className="p-1 rounded-md bg-gray-700/80 hover:bg-red-500/80 
                transition-all duration-200 group-hover:shadow-md
                opacity-0 group-hover:opacity-100"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveArtifact(artifact.id);
              }}
            >
              <Trash2 size={16} className="text-gray-300 hover:text-white" />
            </button>
          )}
        </div>
      </div>
    );
  };

  if (isLoadingArtifacts) {
    return (
      <div className="flex justify-center items-center py-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-400"></div>
        <span className="ml-2 text-sm text-gray-400">Loading artifacts...</span>
      </div>
    );
  }

  return (
    <div className="agent-panel space-y-2 bg-gray-800/50 rounded-md shadow-lg p-2">
      {/* Section Headers */}
      <h3 className="text-sm font-medium text-gray-300 mb-3">In Conversation</h3>

      <div className="space-y-2">
        {/* Active Artifacts Section */}
        {sortedActiveArtifacts.length > 0 && (
          <div className="mb-6">
            <div className="max-h-46 overflow-y-auto">
              <div className="space-y-2">
                {sortedActiveArtifacts.map((artifact) => (
                  <ArtifactItem key={artifact.id} artifact={artifact} isActive={true} />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Available Artifacts Section */}
        <h3 className="text-sm font-medium text-gray-300 mb-3">Project Artifacts</h3>
        
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search artifacts"
          className="w-full p-2 mb-3 rounded-md text-sm
            bg-gray-700/30 text-gray-200 
            focus:ring-2 focus:ring-blue-500/50
            placeholder-gray-500
            transition-all duration-200
            hover:bg-gray-700/50"
        />

        <div className="max-h-44 overflow-y-auto">
          <div className="space-y-2">
            {filteredAvailableArtifacts.map((artifact) => (
              <ArtifactItem key={artifact.id} artifact={artifact} isActive={false} />
            ))}
            {filteredAvailableArtifacts.length === 0 && (
              <div className="text-sm text-gray-500 p-2">
                No artifacts found
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtifactPanel; 