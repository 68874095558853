import React, { useState } from 'react';
import { FaGoogle } from 'react-icons/fa';
import { BsMicrosoft } from 'react-icons/bs';
import { BsSun, BsMoon } from 'react-icons/bs';
import { ChevronRight, ChevronDown, RefreshCw } from 'lucide-react';

const GoogleViewer = ({ url }) => {
  const encodedUrl = encodeURIComponent(url);
  const [timestamp, setTimestamp] = useState(Date.now());

  return (
    <div className="relative w-full h-full">
      <iframe
        src={`https://docs.google.com/viewer?url=${encodedUrl}&embedded=true&t=${timestamp}`}
        width="100%"
        height="100%"
        title="Google Document Viewer"
      />
    </div>
  );
};

const MicrosoftViewer = ({ url }) => {
  const encodedUrl = encodeURIComponent(url);
  const [timestamp, setTimestamp] = useState(Date.now());

  return (
    <div className="relative w-full h-full">
      <iframe
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}&wdFitPage=true&t=${timestamp}`}
        width="100%"
        height="100%"
        title="Microsoft Document Viewer"
      />
    </div>
  );
};

const FileViewer = ({ url, fileType, viewerType = 'google', darkMode }) => {
  const [timestamp, setTimestamp] = useState(Date.now());
  const ViewerComponent = viewerType === 'google' ? GoogleViewer : MicrosoftViewer;

  const handleReload = () => {
    setTimestamp(Date.now());
  };

  switch (fileType) {
    case 'presentation':
    case 'document':
    case 'pdf':
      return (
        <div className={`relative w-full h-full ${darkMode ? 'invert-[.85] hue-rotate-180' : ''}`}>
          <ViewerComponent url={url} timestamp={timestamp} />
        </div>
      );
    default:
      return <p>Unsupported file type</p>;
  }
};

const ViewerControls = ({ viewerType, setViewerType, darkMode, setDarkMode, onReload }) => (
  <>
    <button
      onClick={() => setViewerType('microsoft')}
      className={`p-1.5 rounded-sm transition-colors ${
        viewerType === 'microsoft' 
          ? 'text-blue-400 bg-blue-500/20' 
          : 'text-gray-400 hover:text-gray-200'
      }`}
      title={viewerType === 'microsoft' ? "Currently using Microsoft Office Viewer" : "Switch to Microsoft Office Viewer"}
    >
      <BsMicrosoft className="w-4 h-4" />
    </button>
    <button
      onClick={() => setViewerType('google')}
      className={`p-1.5 rounded-sm transition-colors ${
        viewerType === 'google' 
          ? 'text-blue-400 bg-blue-500/20' 
          : 'text-gray-400 hover:text-gray-200'
      }`}
      title={viewerType === 'google' ? "Currently using Google Docs Viewer" : "Switch to Google Docs Viewer"}
    >
      <FaGoogle className="w-4 h-4" />
    </button>
    <button
      onClick={() => setDarkMode(!darkMode)}
      className={`p-1.5 rounded-sm transition-colors ${
        darkMode 
          ? 'text-blue-400 bg-blue-500/20' 
          : 'text-gray-400 hover:text-gray-200'
      }`}
      title={darkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
    >
      {darkMode ? <BsSun className="w-4 h-4" /> : <BsMoon className="w-4 h-4" />}
    </button>
  </>
);

const JsonView = ({ data }) => {
  const [collapsed, setCollapsed] = useState({});

  const toggleCollapse = (path) => {
    setCollapsed(prev => ({ ...prev, [path]: !prev[path] }));
  };

  const renderValue = (value, path = '', key = null) => {
    const renderKeyValue = (k, v) => {
      if (k === 'change_summary' || k === 'update_summary') {
        return (
          <div className={path ? "ml-4" : ""}>
            <span className="text-blue-400">{k}: </span>
            <div className="ml-4 mt-1 p-2 bg-gray-800/50 rounded text-[11px] italic">
              {renderValueContent(v, `${path}.${k}`, k)}
            </div>
          </div>
        );
      }
      return (
        <div className={path ? "ml-4" : ""}>
          <span className="text-red-400">{k}: </span>
          {renderValueContent(v, `${path}.${k}`, k)}
        </div>
      );
    };

    const renderValueContent = (v, p, k) => {
      if (v === null) return <span className="text-gray-400">null</span>;
      if (typeof v === 'boolean') return <span className="text-purple-400">{v.toString()}</span>;
      if (typeof v === 'number') return <span className="text-blue-400">{v}</span>;
      if (typeof v === 'string') {
        if (k === 'change_summary') {
          return <div className="text-green-400 whitespace-pre-wrap">{v}</div>;
        }
        return <span className="text-green-400">"{v}"</span>;
      }
      if (Array.isArray(v) || typeof v === 'object') {
        const isArray = Array.isArray(v);
        const isCollapsed = collapsed[p];
        return (
          <span>
            <span 
              className="cursor-pointer inline-flex items-center text-gray-200"
              onClick={() => toggleCollapse(p)}
            >
              {isCollapsed ? <ChevronRight size={14} /> : <ChevronDown size={14} />}
            </span>
            {!isCollapsed && (
              <div className="ml-4">
                {isArray
                  ? v.map((item, index) => renderKeyValue(index, item))
                  : Object.entries(v).map(([objKey, val]) => renderKeyValue(objKey, val))
                }
              </div>
            )}
          </span>
        );
      }
      return String(v);
    };

    return key !== null ? renderKeyValue(key, value) : renderValueContent(value, path, key);
  };

  return (
    <div className="font-mono text-xs text-gray-200 leading-relaxed">
      {renderValue(data)}
    </div>
  );
};

export { FileViewer, ViewerControls, JsonView }; 