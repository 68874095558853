import React, { useRef, useState, useEffect, useCallback } from 'react';
import MessageContainer from './components/MessageContainer';
import ControlPanel from './components/ControlPanel';
import InputForm from './components/InputForm';
import StatusPopup from './components/StatusPopup';
import useAgents from './hooks/useAgents';
import useConversations from './hooks/useConversations';
import useAutoChat from './hooks/useAutoChat';
import useProjects from './hooks/useProjects';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import useArtifacts from './hooks/useArtifacts';

function Chat({isLoggedIn, user, handleSignIn, handleLogOut, isAdmin, api }) {
  const [isThreadListVisible, setIsThreadListVisible] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [error, setError] = useState(null);
  const [isCreatingNewThread, setIsCreatingNewThread] = useState(false);
  const threadContainerRef = useRef(null);
  const [isRenamingThread, setIsRenamingThread] = useState(false);
  const [workbenchVisible, setWorkbenchVisible] = useState(false);
  const [isWorkbenchFullscreen, setIsWorkbenchFullscreen] = useState(false);
  const [isAddingAgent, setIsAddingAgent] = useState(false);
  const [showDashboard, setShowDashboard] = useState(true);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [editingAgent, setEditingAgent] = useState(null);
  const quickStartInputRef = useRef(null);
  const [collapseInternalMessages, setCollapseInternalMessages] = useState(false);
  const [showUserManager, setShowUserManager] = useState(false);
  const [runMode, setRunMode] = useState('streaming');
  const [selectedArtifactId, setSelectedArtifactId] = useState(null);
  const [selectedToolOutput, setSelectedToolOutput] = useState(null);

  const handleResize = useCallback(() => {
    setIsSmallScreen(window.innerWidth < 768);
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (isSmallScreen) {
      const isClickInsideThread = threadContainerRef.current && threadContainerRef.current.contains(event.target);
      const isClickOnMenuIcon = event.target.closest('[aria-label="Toggle thread list"]');

      if (!isClickInsideThread && !isClickOnMenuIcon && isThreadListVisible) {
        setIsThreadListVisible(false);
      }
    }
  }, [isSmallScreen, isThreadListVisible]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleResize, handleClickOutside]);

  useEffect(() => {
    // Find existing viewport meta tag or create a new one
    let viewportMeta = document.querySelector('meta[name="viewport"]');
    if (!viewportMeta) {
      viewportMeta = document.createElement('meta');
      viewportMeta.name = 'viewport';
      document.head.appendChild(viewportMeta);
    }

    // Set viewport properties to prevent zooming
    viewportMeta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0';

    // Cleanup function to restore original viewport settings
    return () => {
      viewportMeta.content = 'width=device-width, initial-scale=1';
    };
  }, []);

  const {
    projects,
    selectedProjectId,
    projectData,
    isSharingProject,
    selectProject,
    createProject,
    updateProject,
    deleteProject,
    shareProject
  } = useProjects();

  const {
    threads,
    selectedThreadId,
    setSelectedThreadId,
    currentThreadTitle,
    setCurrentThreadTitle,
    moreThreadsAvailable,
    loadMoreThreads,
    createThread,
    deleteThread,
    renameThread,
    messages,
    setMessages,
    handleGetConversationData,
    handleAddConversationDataSource,
    handleRemoveConversationDataSource,
    fetchAgentsForThread,
    handleThreadSelect,
    activeAgents,
    setActiveAgents,
    conversationData,
    isUpdatingConversationDataSource,
    isLoadingConversation,
    loadMoreMessages,
    hasMoreMessages,
    isDeletingThread,
    isLoadingThreads,
    startPolling,
    stopPolling,
    isPollingEnabled,
    runStatus,
    cancelRun,
    setConversationMetadata,
  } = useConversations({ selectedProjectId });

  const handleCreateNewThread = useCallback(async () => {
    setIsCreatingNewThread(true);
    try {
      await createThread();
      setShowDashboard(false); // Hide dashboard when creating new thread
    } catch (error) {
      setError(error.message);
    } finally {
      setIsCreatingNewThread(false);
    }
  }, [createThread]);

  useEffect(() => {
    if (selectedThreadId) {
      const currentThread = threads.find(thread => thread.id === selectedThreadId);
      setCurrentThreadTitle(currentThread ? currentThread.title : '');
    } else {
      setCurrentThreadTitle('');
    }
  }, [selectedThreadId, threads]);

  const {
    inputValue,
    setInputValue,
    handleSendMessage,
    suggestions,
    setSuggestions,
    selectedAgents,
    setSelectedAgents,
    isMessageLoading,
    abortChat,
    latestArtifactId,
    latestToolOutput,
  } = useAutoChat({
    selectedProjectId,
    selectedThreadId,
    setMessages,
    activeAgents,
    user,
    runMode,
    startPolling,
    stopPolling,
    setConversationMetadata,
    runStatus
  });

  const {
    availableAgents,
    agentData,
    models,
    fetchModels,
    addAgentToConversation,
    removeAgentFromConversation,
    createAgent,
    deleteAgent,
    updateAgent,
    resetDefaultAgent,
    getAgentData,
    addAgentDataSource,
    removeAgentDataSource,
    refreshAgents,
    isLoadingAgents,
    getAgentWithVersions,
  } = useAgents({
    selectedProjectId,
    selectedThreadId,
    activeAgents,
    setActiveAgents,
    fetchAgentsForThread
  });

  const {
    activeArtifacts,
    availableArtifacts,
    onRemoveArtifact,
    isLoadingArtifacts
  } = useArtifacts(selectedProjectId, selectedThreadId);

  const handleProjectSelect = async (projectId) => {
    try {
      // Clear workbench state when changing projects
      setSelectedArtifactId(null);
      setWorkbenchVisible(false);
      setIsWorkbenchFullscreen(false);
      await selectProject(projectId);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleConversationSelect = async (projectId, threadId) => {
    try {
      if (projectId !== selectedProjectId) {
        await handleProjectSelect(projectId);
      }
      await handleThreadSelect(threadId);
      setShowDashboard(false);
      setIsSidebarCollapsed(true);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const lastSelectedProject = localStorage.getItem('lastSelectedProject');
    const lastSelectedThread = localStorage.getItem('lastSelectedThread');
    
    if (lastSelectedProject && projects?.some(p => p._id === lastSelectedProject)) {
      if (lastSelectedThread) {
        handleProjectSelect(lastSelectedProject).then(() => {
          handleThreadSelect(lastSelectedThread);
          setShowDashboard(false);
          setIsSidebarCollapsed(true);
        });
      } else {
        handleProjectSelect(lastSelectedProject).then(() => {
          setIsSidebarCollapsed(false);
        });
      }
    }
  }, [projects]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.role === 'user') {
      setCollapseInternalMessages(false);
    }
  }, [messages]);

  const handleArtifactClick = (artifact) => {
    setSelectedToolOutput(null);
    setSelectedArtifactId(artifact.id);
    setWorkbenchVisible(true);
    setIsWorkbenchFullscreen(true);
    if (artifact.project_id && artifact.project_id !== selectedProjectId) {
      console.warn('Artifact project ID differs from selected project ID');
    }
  };

  return (
    <div className={`flex flex-col h-screen bg-gradient-to-b from-gray-900 via-gray-900 to-gray-950 
      text-white overflow-hidden relative`}>
      <Header 
        isLoggedIn={isLoggedIn}
        user={user}
        handleLogOut={handleLogOut}
        showDashboard={showDashboard}
        setShowDashboard={setShowDashboard}
        selectedProjectId={selectedProjectId}
        projects={projects}
        isSmallScreen={isSmallScreen}
        isSidebarCollapsed={isSidebarCollapsed}
        setIsCollapsed={setIsSidebarCollapsed}
        isAdmin={isAdmin}
        showUserManager={showUserManager}
        setShowUserManager={setShowUserManager}
      />

      <main className="flex flex-1 overflow-hidden pt-[32px] relative bg-transparent">
        {!showDashboard && (
          <ControlPanel
            ref={threadContainerRef}
            threads={threads}
            selectedThreadId={selectedThreadId}
            handleThreadSelect={handleThreadSelect}
            loadMoreThreads={loadMoreThreads}
            moreThreadsAvailable={moreThreadsAvailable}
            deleteThread={deleteThread}
            renameThread={renameThread}
            isLoadingThreads={isLoadingThreads}
            isRenamingThread={isRenamingThread}
            selectedProjectId={selectedProjectId}
            activeAgents={activeAgents}
            availableAgents={availableAgents}
            agentData={agentData}
            addAgentToConversation={addAgentToConversation}
            updateAgent={updateAgent}
            deleteAgent={deleteAgent}
            resetDefaultAgent={resetDefaultAgent}
            removeAgentFromConversation={removeAgentFromConversation}
            createAgent={createAgent}
            getAgentData={getAgentData}
            addAgentDataSource={addAgentDataSource}
            removeAgentDataSource={removeAgentDataSource}
            refreshAgents={refreshAgents}
            isLoadingAgents={isLoadingAgents}
            isAddingAgent={isAddingAgent}
            setIsAddingAgent={setIsAddingAgent}
            isCollapsed={isSidebarCollapsed}
            setIsCollapsed={setIsSidebarCollapsed}
            isSmallScreen={isSmallScreen}
            isCreatingNewThread={isCreatingNewThread}
            createNewThread={handleCreateNewThread}
            className="bg-gray-800"
            getAgentWithVersions={getAgentWithVersions}
            conversationData={conversationData}
            handleGetConversationData={handleGetConversationData}
            handleRemoveConversationDataSource={handleRemoveConversationDataSource}
            models={models}
            activeArtifacts={activeArtifacts}
            availableArtifacts={availableArtifacts}
            onRemoveArtifact={onRemoveArtifact}
            isLoadingArtifacts={isLoadingArtifacts}
            onArtifactClick={handleArtifactClick}
          />
        )}

        <section className={`chat-section flex-1 flex flex-col h-full overflow-hidden 
          transition-all duration-300 ease-in-out 
          ${showDashboard ? 'w-full' : ''} 
          ${!isSmallScreen && !workbenchVisible && isSidebarCollapsed ? 'max-w-[900px] mx-auto w-full' : 'w-full'}
          bg-transparent
          ${isMessageLoading && isSmallScreen ? 'pb-20' : ''}`}
        >
          {showDashboard ? (
            <Dashboard 
              onSelectProject={handleProjectSelect}
              handleConversationSelect={handleConversationSelect}
              projects={projects}
              threads={threads}
              selectedProjectId={selectedProjectId}
              user={user}
              handleLogOut={handleLogOut}
              createProject={createProject}
              updateProject={updateProject}
              deleteProject={deleteProject}
              agents={availableAgents}
              setShowDashboard={setShowDashboard}
              showDashboard={showDashboard}
              createAgent={createAgent}
              updateAgent={updateAgent}
              deleteAgent={deleteAgent}
              agentData={agentData}
              getAgentData={getAgentData}
              addDataSource={addAgentDataSource}
              removeDataSource={removeAgentDataSource}
              resetAgent={resetDefaultAgent}
              shareProject={shareProject}
              inputRef={quickStartInputRef}
              setIsSidebarCollapsed={setIsSidebarCollapsed}
              deleteThread={deleteThread}
              isDeletingThread={isDeletingThread}
              isLoadingThreads={isLoadingThreads}
              isSmallScreen={isSmallScreen}
              getAgentWithVersions={getAgentWithVersions}
              isAdmin={isAdmin}
              api={api}
              showUserManager={showUserManager}
              setShowUserManager={setShowUserManager}
              className="bg-transparent"
              createNewThread={handleCreateNewThread}
              isCreatingNewThread={isCreatingNewThread}
              models={models}
            />
          ) : (
            <>
              <MessageContainer
                  selectedProjectId={selectedProjectId}
                  selectedThreadId={selectedThreadId}
                  messages={messages}
                  isMessageLoading={isMessageLoading}
                  isLoadingConversation={isLoadingConversation}
                  isSmallScreen={isSmallScreen}
                  handleSendMessage={handleSendMessage}
                  onLoadMoreMessages={loadMoreMessages}
                  hasMoreMessages={hasMoreMessages}
                  isLoadingThreads={isLoadingThreads}
                  workbenchVisible={workbenchVisible}
                  setWorkbenchVisible={setWorkbenchVisible}
                  isAddingAgent={isAddingAgent}
                  collapseInternalMessages={collapseInternalMessages}
                  latestArtifactId={latestArtifactId}
                  latestToolOutput={latestToolOutput}
                  runMode={runMode}
                  onRunModeChange={setRunMode}
                  runStatus={runStatus}
                  onCancelRun={cancelRun}
                  updateProject={updateProject}
                  projectData={projectData}
                  updateAgent={updateAgent}
                  agentData={agentData}
                  getAgentWithVersions={getAgentWithVersions}
                  models={models}
                  removeDataSource={removeAgentDataSource}
                  addDataSource={addAgentDataSource}
                  getAgentData={getAgentData}
                  selectedArtifactId={selectedArtifactId}
                  setSelectedArtifactId={setSelectedArtifactId}
                  selectedToolOutput={selectedToolOutput}
                  setSelectedToolOutput={setSelectedToolOutput}
                  isWorkbenchFullscreen={isWorkbenchFullscreen}
                  setIsWorkbenchFullscreen={setIsWorkbenchFullscreen}
              />
              {(!isSmallScreen || !isThreadListVisible) && !(workbenchVisible && isSmallScreen) && (
                <InputForm
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  handleSendMessage={handleSendMessage}
                  suggestions={suggestions}
                  setSuggestions={setSuggestions}
                  selectedAgents={selectedAgents}
                  setSelectedAgents={setSelectedAgents}
                  activeAgents={activeAgents}
                  selectedThreadId={selectedThreadId}
                  handleAddConversationDataSource={handleAddConversationDataSource}
                  isMessageLoading={isMessageLoading}
                  createThread={createThread}
                  isCreatingNewThread={isCreatingNewThread}
                  selectedProjectId={selectedProjectId}
                  abortChat={abortChat}
                  messages={messages}
                  isAddingAgent={isAddingAgent}
                  quickStart={false}
                  disabled={isMessageLoading || isLoadingConversation}
                  setIsSidebarCollapsed={setIsSidebarCollapsed}
                  setCollapseInternalMessages={setCollapseInternalMessages}
                  runMode={runMode}
                  runStatus={runStatus}
                  onRunModeChange={setRunMode}
                  onCancel={cancelRun}
                />
              )}
            </>
          )}
        </section>
      </main>

      {error && (
        <div className="fixed inset-0 bg-gray-900/80 backdrop-blur-sm flex justify-center items-center z-50">
          <StatusPopup message={error} onClose={() => setError(null)} />
        </div>
      )}
    </div>
  );
}

export default Chat;
