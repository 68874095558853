import React, { useState, useEffect } from 'react';
import { useWhiteboard } from '../hooks/useWhiteboard';
import SharedMarkdown from '../utils/SharedMarkdown';
import { Plus, ThumbsUp, ThumbsDown, X } from 'lucide-react';

// Fixed styles for each entry type
const ENTRY_STYLES = {
  idea: {
    bg: 'bg-[#2D3748]',
    text: 'text-gray-100',
    border: 'border-[#4A5568]',
    tag: 'bg-[#1A202C]'
  },
  question: {
    bg: 'bg-[#553C9A]',
    text: 'text-gray-100',
    border: 'border-[#6B46C1]',
    tag: 'bg-[#44337A]'
  },
  note: {
    bg: 'bg-[#2C5282]',
    text: 'text-gray-100',
    border: 'border-[#3182CE]',
    tag: 'bg-[#2A4365]'
  },
  decision: {
    bg: 'bg-[#285E61]',
    text: 'text-gray-100',
    border: 'border-[#319795]',
    tag: 'bg-[#234E52]'
  },
  action: {
    bg: 'bg-[#744210]',
    text: 'text-gray-100',
    border: 'border-[#975A16]',
    tag: 'bg-[#5F370E]'
  },
  reference: {
    bg: 'bg-[#2D3748]',
    text: 'text-gray-100',
    border: 'border-[#4A5568]',
    tag: 'bg-[#1A202C]'
  },
  proposal: {
    bg: 'bg-[#2C5282]',
    text: 'text-gray-100',
    border: 'border-[#3182CE]',
    tag: 'bg-[#2A4365]'
  }
};

const NewEntryForm = ({ onSubmit, onCancel }) => {
  const [entryType, setEntryType] = useState('note');
  const [content, setContent] = useState('');
  const [tags, setTags] = useState('');
  const [explanation, setExplanation] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      entry_type: entryType,
      content,
      tags: tags.split(',').map(t => t.trim()).filter(t => t),
      explanation: explanation || undefined
    });
  };

  return (
    <form onSubmit={handleSubmit} className="bg-gray-800 p-4 rounded-lg shadow-lg">
      <select 
        value={entryType}
        onChange={(e) => setEntryType(e.target.value)}
        className="w-full mb-2 bg-gray-700 text-gray-100 rounded p-2"
      >
        {Object.keys(ENTRY_STYLES).map(type => (
          <option key={type} value={type}>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </option>
        ))}
      </select>
      
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Entry content..."
        className="w-full mb-2 bg-gray-700 text-gray-100 rounded p-2"
        rows={4}
        required
      />
      
      <input
        value={tags}
        onChange={(e) => setTags(e.target.value)}
        placeholder="Tags (comma-separated)"
        className="w-full mb-2 bg-gray-700 text-gray-100 rounded p-2"
      />
      
      <input
        value={explanation}
        onChange={(e) => setExplanation(e.target.value)}
        placeholder="Explanation (optional)"
        className="w-full mb-2 bg-gray-700 text-gray-100 rounded p-2"
      />
      
      <div className="flex justify-end gap-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-3 py-1 bg-gray-700 text-gray-300 rounded hover:bg-gray-600"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-3 py-1 bg-blue-600 text-white rounded hover:bg-blue-500"
        >
          Add Entry
        </button>
      </div>
    </form>
  );
};

const WhiteboardViewer = ({ projectId, conversationId }) => {
  const { 
    entries, 
    fetchEntries, 
    isLoading, 
    error,
    addReaction,
    addEntry,
  } = useWhiteboard();
  
  const [showNewEntryForm, setShowNewEntryForm] = useState(false);

  useEffect(() => {
    if (projectId && conversationId) {
      fetchEntries(projectId, conversationId);
    }
  }, [projectId, conversationId, fetchEntries]);

  const handleAddEntry = async (newEntry) => {
    try {
      await addEntry(projectId, conversationId, newEntry);
      setShowNewEntryForm(false);
    } catch (err) {
      console.error('Failed to add entry:', err);
    }
  };

  const handleReaction = async (entry, reaction) => {
    try {
      if (entry.reactions?.some(r => r.reaction === reaction)) {
        await addReaction(projectId, conversationId, entry.id, null);
      } else {
        await addReaction(projectId, conversationId, entry.id, reaction);
      }
    } catch (err) {
      console.error('Failed to add reaction:', err);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full text-[#1F2937]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full text-red-600">
        <p>Error loading whiteboard entries: {error}</p>
      </div>
    );
  }

  if (!entries?.length) {
    return (
      <div className="flex items-center justify-center h-full text-[#1F2937]">
        <p>No entries found</p>
      </div>
    );
  }

  return (
    <div className="h-full overflow-y-auto p-3 bg-[#111827]">
      {/* Add Entry Button */}
      <div className="sticky top-0 z-10 mb-3 flex justify-end">
        <button
          onClick={() => setShowNewEntryForm(true)}
          className="bg-blue-600 text-white rounded-full p-2 hover:bg-blue-500 shadow-lg"
        >
          <Plus size={20} />
        </button>
      </div>

      {/* New Entry Form Modal */}
      {showNewEntryForm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <NewEntryForm
            onSubmit={handleAddEntry}
            onCancel={() => setShowNewEntryForm(false)}
          />
        </div>
      )}

      {/* Entries Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
        {entries.map((entry) => {
          const styles = ENTRY_STYLES[entry.entry_type] || ENTRY_STYLES.note;
          
          return (
            <div
              key={entry.id}
              className={`${styles.bg} ${styles.text} rounded-lg shadow-xl p-3 
                transform hover:scale-105 transition-transform duration-200 relative 
                border ${styles.border} backdrop-blur-sm bg-opacity-90 rotate-1`}
            >
              <div className={`absolute -top-1.5 -right-1.5 ${styles.tag} ${styles.text} 
                rounded-full px-1.5 py-0.5 text-[10px] font-semibold shadow-md 
                border ${styles.border} backdrop-blur-sm`}>
                {entry.entry_type.charAt(0).toUpperCase() + entry.entry_type.slice(1)}
              </div>

              <div className="prose prose-invert max-w-none prose-p:my-1">
                <SharedMarkdown className="text-[9px] leading-snug font-medium">
                  {entry.content}
                </SharedMarkdown>
              </div>

              {entry.explanation && (
                <div className={`mt-2 pt-1.5 border-t ${styles.border} border-opacity-50`}>
                  <div className={`text-[9px] font-semibold mb-0.5 ${styles.text} opacity-90`}>
                    Explanation:
                  </div>
                  <div className={`text-[9px] leading-snug ${styles.text} opacity-80`}>
                    {entry.explanation}
                  </div>
                </div>
              )}

              {entry.tags?.length > 0 && (
                <div className="mt-2 flex flex-wrap gap-1">
                  {entry.tags.map((tag, tagIndex) => (
                    <span
                      key={tagIndex}
                      className={`${styles.tag} ${styles.text} rounded-full px-1.5 py-0.5 
                        text-[10px] font-medium shadow-sm border ${styles.border} border-opacity-50 backdrop-blur-sm`}
                    >
                      #{tag}
                    </span>
                  ))}
                </div>
              )}

              <div className="mt-2 pt-2 border-t border-gray-700/30 flex items-center justify-between">
                <div className="flex gap-2">
                  <button
                    onClick={() => handleReaction(entry, 'like')}
                    className={`p-1 rounded hover:bg-gray-700/30 ${
                      entry.reactions?.some(r => r.reaction === 'like') 
                        ? 'text-green-400 bg-gray-700/30' 
                        : 'text-gray-400'
                    }`}
                  >
                    <ThumbsUp size={12} />
                    {entry.reactions?.filter(r => r.reaction === 'like').length || 0}
                  </button>
                  <button
                    onClick={() => handleReaction(entry, 'dislike')}
                    className={`p-1 rounded hover:bg-gray-700/30 ${
                      entry.reactions?.some(r => r.reaction === 'dislike') 
                        ? 'text-red-400 bg-gray-700/30' 
                        : 'text-gray-400'
                    }`}
                  >
                    <ThumbsDown size={12} />
                    {entry.reactions?.filter(r => r.reaction === 'dislike').length || 0}
                  </button>
                </div>
                
                <div className={`text-[9px] ${styles.text} font-medium opacity-75`}>
                  by {entry.agent_name || 'Unknown'}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(WhiteboardViewer);