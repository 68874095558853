import { useState, useEffect, useRef, useCallback } from 'react';
import useApi from './useApi';
import { handleHttpError } from '../utils/errorHandling';
import { SenderType } from '../types/Message';

const useAutoChat = ({
  selectedProjectId,
  selectedThreadId,
  setMessages,
  activeAgents,
  user,
  runMode,
  startPolling,
  stopPolling,
  setConversationMetadata,
  runStatus
}) => {
  const { api } = useApi();
  // Chat state
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const abortControllerRef = useRef(null);
  const [isWaitingForInput, setIsWaitingForInput] = useState(false);
  const [inputTimeout, setInputTimeout] = useState(null);
  const timeoutRef = useRef(null);
  const [latestArtifactId, setLatestArtifactId] = useState(null);
  const [latestToolOutput, setLatestToolOutput] = useState(null);

  const streamResponses = async (response) => {
    let partialMessage = '';

    const processMessage = (jsonStr) => {
      try {
        const message = JSON.parse(jsonStr);
        
        // For transcribed audio messages, we need to ensure they have a streaming_id
        if (message.metadata?.is_audio) {
          const streamingId = message.id || `strm_${Date.now()}`;
          message.metadata.streaming_id = streamingId;
        }

        const streamingId = message.metadata?.streaming_id || message.id;
        if (!streamingId) {
          console.warn('Message missing streaming_id:', message);
          return;
        }

        // Track both artifacts and tool outputs with timestamps
        if (message.artifacts?.length > 0) {
          setLatestArtifactId({
            id: message.artifacts[message.artifacts.length - 1].id,
            timestamp: Number(message.created_at)
          });
        }
        
        if (message.tool_outputs?.length > 0) {
          setLatestToolOutput({
            message: {
              ...message,
              tool_outputs: message.tool_outputs
            },
            toolOutputIndex: message.tool_outputs.length - 1,
            timestamp: Number(message.created_at)
          });
        }

        setMessages((msgs) => {
          const updatedMessages = [...msgs];
          const existingMessageIndex = updatedMessages.findIndex(m => 
            m.metadata?.streaming_id === streamingId || m.id === streamingId
          );

          if (existingMessageIndex !== -1) {
            // Update existing message
            const updatedMetadata = {
              ...updatedMessages[existingMessageIndex].metadata,
              ...message.metadata,
              is_streaming: message.metadata?.is_streaming,
              streaming_id: streamingId,
              sender_type: message.metadata?.sender_type || SenderType.AGENT,
              short_message: message.metadata?.short_message
            };

            // Only update speaker_rationale if this is not a termination message
            if (!message.metadata?.is_termination) {
              updatedMetadata.speaker_rationale = message.metadata?.speaker_rationale;
            }

            // Add termination info if present
            if (message.metadata?.is_termination) {
              updatedMetadata.termination_reason = message.metadata?.termination_reason;
              updatedMetadata.is_termination = true;
            }

            updatedMessages[existingMessageIndex] = {
              ...updatedMessages[existingMessageIndex],
              content: message.metadata?.is_streaming ? 
                updatedMessages[existingMessageIndex].content + message.content : 
                message.content || updatedMessages[existingMessageIndex].content,
              metadata: updatedMetadata,
              tool_outputs: message.tool_outputs || updatedMessages[existingMessageIndex].tool_outputs,
              artifacts: message.artifacts || updatedMessages[existingMessageIndex].artifacts
            };
          } else {
            // Add new message
            const metadata = {
              ...message.metadata,
              name: message.metadata?.name || '',
              icon: message.metadata?.icon || '',
              streaming_id: streamingId,
              sender_type: message.metadata?.sender_type || SenderType.AGENT,
              short_message: message.metadata?.short_message
            };

            // Only include speaker_rationale if not a termination message
            if (!message.metadata?.is_termination) {
              metadata.speaker_rationale = message.metadata?.speaker_rationale;
            }

            // Add termination info if present
            if (message.metadata?.is_termination) {
              metadata.termination_reason = message.metadata?.termination_reason;
              metadata.is_termination = true;
            }

            updatedMessages.push({
              id: streamingId,
              role: message.role,
              content: message.content || '',
              metadata: metadata,
              artifacts: message.artifacts || [],
              tool_outputs: message.tool_outputs || [],
              created_at: message.created_at
            });

            // Handle input request
            if (message.metadata?.needs_input) {
              setIsWaitingForInput(true);
              setIsMessageLoading(false);
            }
          }

          return updatedMessages;
        });
      } catch (error) {
        console.error('Error parsing JSON:', error, 'String:', jsonStr);
      }
    };

    const readStream = async () => {
      try {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          try {
            const { done, value } = await reader.read();
            
            if (done) {
              console.log('Stream completed normally');
              // Requery status after stream ends
              const statusResponse = await fetch(
                `${api.defaults.baseURL}/conversations/${selectedProjectId}/${selectedThreadId}/messages`,
                { credentials: 'include' }
              );
              const { run_status } = await statusResponse.json();
              setConversationMetadata({ run_status });
              break;
            }

            // Check if abort has been requested
            if (abortControllerRef.current?.signal.aborted) {
              console.log('Stream aborted by client, reason:', abortControllerRef.current.signal.reason);
              reader.cancel();
              break;
            }

            const chunk = new TextDecoder("utf-8").decode(value);
            partialMessage += chunk;

            let newlineIndex;
            while ((newlineIndex = partialMessage.indexOf('\n')) !== -1) {
              const line = partialMessage.slice(0, newlineIndex);
              partialMessage = partialMessage.slice(newlineIndex + 1);
              if (line.startsWith('data: ')) {
                processMessage(line.substring(6).trim());
              }
            }

            // Process any remaining partial message
            if (partialMessage.startsWith('data: ')) {
              processMessage(partialMessage.substring(6).trim());
            }
          } catch (readError) {
            console.error('Error reading stream chunk:', readError);
            if (readError.name === 'AbortError') {
              console.log('Stream aborted during read');
              break;
            }
            throw readError;
          }
        }
      } catch (error) {
        console.error('Fatal stream error:', error);
        setConversationMetadata({ run_status: 'failed' });
        throw error; // Re-throw to be handled by caller
      }
    };

    await readStream();
  };

  const setupAbortController = () => {
    abortControllerRef.current = new AbortController();
    return abortControllerRef.current.signal;
  };
  
  // Helper function to check if run is finished
  const isRunFinished = (status) => {
    return ['completed', 'failed', 'retriable'].includes(status?.toLowerCase());
  };

  // Helper to check if we should be polling
  const shouldPoll = () => {
    return runMode === 'background' && !isRunFinished(runStatus);
  };

  const startAutoChat = async ({ starting_message, offset = null }) => {
    if (!selectedProjectId || !selectedThreadId) {
      handleHttpError(new Error('No project selected'), 'starting chat');
      return;
    }

    // Set initial status based on runMode
    setConversationMetadata({ run_status: runMode });
    
    const endpoint = runMode === 'background' ? 
      `${api.defaults.baseURL}/conversations/${selectedProjectId}/start-background-chat` :
      `${api.defaults.baseURL}/conversations/${selectedProjectId}/start-streaming-chat`;

    try {
      const signal = setupAbortController();

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'text/event-stream',
        },
        body: JSON.stringify({
          conversation_id: selectedThreadId,
          user_message: starting_message,
          temperature: 0.7,
          message_offset_timestamp: offset ? Math.floor(offset) : null,
          run_status: runMode
        }),
        credentials: 'include',
        signal: signal
      });

      if (!response.ok) {
        if (response.status === 409) {
          const error = await response.json();
          handleHttpError(new Error(error.detail || "Please wait for the current run to complete or cancel it"), 'starting chat');
          return;
        }
        // Try to get error details
        const errorText = await response.text();
        console.error('Server error:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
      }

      if (runMode === 'background') {
        const result = await response.json();
        const newStatus = result.run_status || 'background';
        setConversationMetadata({ run_status: newStatus });

        console.log('Background chat started, checking polling conditions:', {
          runMode,
          newStatus,
          shouldPoll: runMode === 'background' && !['completed', 'failed', 'retriable'].includes(newStatus)
        });

        // Always start polling when in background mode
        if (runMode === 'background') {
          startPolling();
        }
      } else {
        await streamResponses(response);
      }

    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Chat aborted:', error);
      } else {
        console.error('Chat error details:', error);
        handleHttpError(error, 'starting auto chat');
        setConversationMetadata({ run_status: 'failed' });
      }
    } finally {
      setIsMessageLoading(false);
    }
  };

  const parseAgentMentions = (text = '') => {
    if (!Array.isArray(activeAgents) || !text) {
        return [];
    }

    return activeAgents
      .map(agent => {
        const mention = `@${agent?.name || ''}`.toLowerCase(); // Lowercase the full mention
        const index = text.toLowerCase().indexOf(mention); // Search in lowercased text
        return { agent, index };
      })
      .filter(({ index, agent }) => index !== -1 && agent?.name)
      .sort((a, b) => a.index - b.index)
      .reduce((unique, { agent }) => {
        const name = agent.name.toLowerCase();
        return unique.some(a => a.name.toLowerCase() === name) ? unique : [...unique, agent];
      }, []);
  };

  // Helper to create a temporary message
  const createUserMessage = (content, timestamp, audioBlob = null) => ({
    id: `temp_${timestamp}`,
    role: 'user',
    content,
    created_at: timestamp,
    metadata: {
      name: user?.name,
      sender_type: SenderType.USER,
      run_status: runMode,
      ...(audioBlob ? { is_audio: true } : {})
    },
    artifacts: []
  });

  // Helper to create an edited message
  const createEditedMessage = (originalMessage, newContent, timestamp) => ({
    ...originalMessage,
    content: newContent,
    edited: true,
    editedAt: timestamp,
    metadata: {
      ...originalMessage.metadata,
      run_status: runMode
    }
  });

  // Helper to handle message editing
  const handleMessageEdit = (messages, editTimestamp, newContent, timestamp) => {
    const editedIndex = messages.findIndex(msg => msg.created_at === editTimestamp);
    if (editedIndex === -1) return messages;
    
    const editedMessage = createEditedMessage(
      messages[editedIndex],
      newContent,
      timestamp
    );
    
    return [...messages.slice(0, editedIndex), editedMessage];
  };

  const handleSendMessage = async (editTimestamp = null, content = null, audioBlob = null) => {
    setIsWaitingForInput(false);
    if (!selectedProjectId || !selectedThreadId) {
      console.error('No active conversation');
      return;
    }

    let messageContent = content || inputValue.trim();
    setInputValue('');

    if (messageContent || audioBlob) {
      const timestamp = Date.now() / 1000;

      if (runMode === 'streaming') {
        if (editTimestamp) {
          setMessages(msgs => handleMessageEdit(msgs, editTimestamp, messageContent, timestamp));
        } else {
          setMessages(msgs => [...msgs, createUserMessage(messageContent, timestamp, audioBlob)]);
        }
      } else {
        if (editTimestamp) {
          setMessages(msgs => {
            const editedIndex = msgs.findIndex(msg => msg.created_at === editTimestamp);
            if (editedIndex === -1) return msgs;
            return msgs.slice(0, editedIndex);
          });
        }
      }

      setIsMessageLoading(true);
      try {
        if (audioBlob) {
          await handleAudioChat(audioBlob);
        } else {
          await startAutoChat({
            starting_message: messageContent,
            offset: editTimestamp
          });
        }
      } finally {
        setIsMessageLoading(false);
        setSelectedAgents([]);
      }
    }
  };

  const abortChat = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsMessageLoading(false);
      
      // Update the latest streaming message to mark it as stopped
      setMessages((msgs) => {
        const updatedMessages = [...msgs];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage?.metadata?.is_streaming) {
          lastMessage.metadata = {
            ...lastMessage.metadata,
            is_streaming: false,
            stop_reason: "user_cancelled",
            is_termination: true,
            termination_reason: "Cancelled by user"
          };
        }
        return updatedMessages;
      });
      
      // Update run status to completed when chat is aborted
      setConversationMetadata({ run_status: 'completed' });
      
      // Use the existing cancel-run endpoint
      try {
        await fetch(
          `${api.defaults.baseURL}/conversations/${selectedProjectId}/${selectedThreadId}/cancel-run`,
          {
            method: 'POST',
            credentials: 'include'
          }
        );
      } catch (error) {
        console.error('Error cancelling chat:', error);
      }
    }
  };

  const handleAudioChat = useCallback(async (audioBlob) => {
    if (!selectedProjectId || !selectedThreadId) {
        handleHttpError(new Error('No conversation selected'), 'processing audio chat');
    }

    try {
        if (!audioBlob) {
            throw new Error('No audio recorded');
        }

        const formData = new FormData();
        
        // Create the request object matching AutoChatRequest schema exactly
        const request = {
            conversation_id: selectedThreadId,
            user_message: '',  // Empty string for initial message
            agent_id: null,  // Optional field
            message_offset_timestamp: null,  // Optional field
            temperature: 0.7
        };

        // Add the request as JSON string
        formData.append('request', JSON.stringify(request));
        // Add the audio file
        formData.append('audio_file', audioBlob, 'audio.webm');

        const signal = setupAbortController();
        const response = await fetch(`${api.defaults.baseURL}/conversations/${selectedProjectId}/audio-chat`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
            signal: signal
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        await streamResponses(response);

    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Audio chat aborted');
        } else {
            console.error('Error in handleAudioChat:', error);
            setMessages(msgs => [...msgs, { 
                role: 'assistant', 
                content: 'Error processing audio chat. Please try again.' 
            }]);
        }
    }
  }, [selectedProjectId, selectedThreadId, setMessages, api.defaults.baseURL]);
 
  // Add effect to handle conversation changes
  useEffect(() => {
    // Only abort ongoing chat when conversation changes
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        setIsMessageLoading(false);
      }
    };
  }, [selectedThreadId]);


  // Update cleanup effect to handle run completion
  useEffect(() => {
    // Only abort if we're in a terminal state AND we have an active stream
    if (['completed', 'failed', 'retriable'].includes(runStatus) && isMessageLoading) {
      console.log('Aborting stream due to run status:', runStatus);
      abortControllerRef.current?.abort("Run status: " + runStatus);
      setIsMessageLoading(false);
    }

    // Handle polling separately
    if (!shouldPoll()) {
      stopPolling();
    }
  }, [runStatus, runMode, stopPolling, isMessageLoading]);

  // Keep just the polling effect
  useEffect(() => {
    if (!shouldPoll()) {
      stopPolling();
    }
  }, [runStatus, runMode, stopPolling]);

  // Effect to handle run status changes
  useEffect(() => {
    // Stop polling when run finishes
    if (!shouldPoll()) {
      stopPolling();
    }
  }, [runStatus, runMode, stopPolling, isRunFinished]);

  return {
    inputValue,
    setInputValue,
    handleSendMessage,
    suggestions,
    setSuggestions,
    selectedAgents,
    setSelectedAgents,
    isMessageLoading,
    abortChat,
    isWaitingForInput,
    inputTimeout,
    latestArtifactId,
    latestToolOutput,
    startAutoChat
  };
};

export default useAutoChat;
