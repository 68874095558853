import React from 'react';
import { getArtifactIcon } from '../../utils/iconUtils';

const ArtifactButton = ({ artifact, onClick }) => {
  const icon = getArtifactIcon(artifact.type);

  const handleClick = async () => {
    if (artifact.id) {
      onClick(artifact);
    } else {
      console.error('No id found for artifact:', artifact);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`flex items-center gap-1.5 text-xs bg-gray-800 hover:bg-gray-700
        text-gray-300 hover:text-gray-100 rounded-md px-2.5 py-1.5 
        transition-colors shadow-sm hover:shadow-md w-52 md:w-fit max-w-[500px]`}
      title={artifact.name}
    >
      <span className="text-indigo-400 flex-shrink-0">{icon}</span>
      <span className="font-medium text-left truncate">{artifact.name}</span>
    </button>
  );
};

export default ArtifactButton; 