import { useState, useCallback } from 'react';
import useApi from './useApi';

export const useWhiteboard = () => {
  const { api } = useApi();
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchEntries = useCallback(async (projectId, conversationId) => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await api.get(`/whiteboards/${projectId}/${conversationId}/entries`);
      setEntries(response.data.entries);
    } catch (err) {
      console.error('Error fetching whiteboard entries:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const fetchByTags = useCallback(async (projectId, conversationId, tags) => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await api.get(`/whiteboards/${projectId}/${conversationId}/entries/by-tags`, {
        params: { tags }
      });
      setEntries(response.data.entries);
    } catch (err) {
      console.error('Error fetching whiteboard entries by tags:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const fetchPopular = useCallback(async (projectId, conversationId, minReactions = 2) => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await api.get(`/whiteboards/${projectId}/${conversationId}/entries/popular`, {
        params: { min_reactions: minReactions }
      });
      setEntries(response.data.entries);
    } catch (err) {
      console.error('Error fetching popular whiteboard entries:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const addReaction = useCallback(async (projectId, conversationId, entryId, reaction) => {
    try {
      setError(null);
      
      // Optimistically update the UI
      setEntries(currentEntries => 
        currentEntries.map(entry => {
          if (entry.id !== entryId) return entry;
          
          // Deep clone the entry
          const updatedEntry = JSON.parse(JSON.stringify(entry));
          
          // Remove existing reaction from this user if any
          updatedEntry.reactions = updatedEntry.reactions.filter(
            r => r.reactor_id !== api.getUserId()
          );
          
          // Add new reaction if not null
          if (reaction) {
            updatedEntry.reactions.push({
              reactor_id: api.getUserId(),
              reactor_name: api.getUserName(),
              reaction,
              timestamp: Math.floor(Date.now() / 1000)
            });
          }
          
          return updatedEntry;
        })
      );

      // Make API call
      await api.post(`/whiteboards/${projectId}/${conversationId}/entries/${entryId}/react`, {
        reaction
      });

      // Fetch latest state in background to ensure consistency
      const response = await api.get(`/whiteboards/${projectId}/${conversationId}/entries`);
      setEntries(response.data.entries);
      
    } catch (err) {
      console.error('Error adding reaction to whiteboard entry:', err);
      setError(err.message);
      
      // Revert to latest known good state on error
      await fetchEntries(projectId, conversationId);
    }
  }, [api, fetchEntries]);

  const addEntry = useCallback(async (projectId, conversationId, newEntry) => {
    try {
      await api.post(`/whiteboards/${projectId}/${conversationId}/entries`, newEntry);
      await fetchEntries(projectId, conversationId);
    } catch (err) {
      console.error('Error adding entry to whiteboard:', err);
      setError(err.message);
    }
  }, [api, fetchEntries]);

  return {
    entries,
    isLoading,
    error,
    fetchEntries,
    fetchByTags,
    fetchPopular,
    addReaction,
    addEntry
  };
}; 