import { useState, useEffect } from 'react';
import useApi from './useApi';

const useTokens = () => {
    const { api, getUsage } = useApi();
    const [usage, setUsage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const getTierInfo = (tier) => {
        switch(tier) {
            case 'PRO':
                return {
                    requests: '10,000 requests/month'
                };
            case 'FREE':
                return {
                    requests: '100 requests/month'
                };
            default: // STANDARD
                return {
                    requests: '500 requests/month'
                };
        }
    };

    const formatNumber = (num) => new Intl.NumberFormat().format(num || 0);

    const fetchUsage = async () => {
        try {
            setIsLoading(true);
            const response = await getUsage();
            
            // Process and structure the usage data
            const processedUsage = {
                current: {
                    user_requests: parseInt(response.current.user_requests) || 0,
                    credits: parseFloat(response.current.credits) || 0,
                    tier: response.current.tier || 'STANDARD',
                    next_reset: response.current.next_reset,
                    models: Object.entries(response.current.models || {}).map(([modelId, stats]) => ({
                        id: modelId,
                        name: stats.name,
                        user_requests: stats.user_requests || 0,
                        system_requests: stats.system_requests || 0,
                        user_tokens: stats.user_tokens || 0,
                        system_tokens: stats.system_tokens || 0,
                        total_tokens: stats.total_tokens || 0
                    }))
                },
                limits: {
                    request_limit: parseInt(response.limits.request_limit) || 0,
                    remaining_requests: parseInt(response.limits.remaining_requests) || 0
                }
            };

            setUsage(processedUsage);
            setError(null);
        } catch (err) {
            setError(err);
            console.error('Error fetching usage:', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (api) {
            fetchUsage();
        }
    }, [api]);

    return {
        usage,
        isLoading,
        error,
        getTierInfo,
        formatNumber,
        refreshUsage: fetchUsage
    };
};

export default useTokens; 