import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { User, UserPlus, Share2, MessageSquarePlus, History, LucideDatabase as Database, File, Files } from 'lucide-react';

export const UserIcon = () => (
  <motion.div
    className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-gradient-to-br from-blue-500 via-blue-600 to-blue-500 shadow-sm"
    initial={{ scale: 0.8 }}
    animate={{ 
      scale: [0.8, 1, 0.8],
      rotate: [0, 2, -2, 0]
    }}
    transition={{ 
      duration: 4,
      repeat: Infinity,
      ease: "easeInOut"
    }}
  >
    <User size={14} className="text-white/90" />
  </motion.div>
);

export const MessageAgentIcon = () => (
  <motion.div
    className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-blue-500"
    initial={{ scale: 0.8 }}
    animate={{ 
      scale: [0.8, 1, 0.8],
      rotate: [0, 2, -2, 0]
    }}
    transition={{ 
      duration: 4,
      repeat: Infinity,
      ease: "easeInOut"
    }}
  >
    <FontAwesomeIcon 
      icon={faRobot}
      className="h-3.5 w-3.5 text-white" 
    />
  </motion.div>
);

export const HistoryIcon = () => (
  <motion.div
    initial={{ scale: 0.8 }}
    animate={{ 
      scale: [0.8, 1, 0.8],
      rotate: [0, 1, -1, 0]
    }}
  >
    <History size={20} className="text-gray-200" />
  </motion.div>
);

export const NewChatIcon = () => (
  <motion.div
    initial={{ scale: 0.8 }}
    animate={{ 
      scale: [0.8, 1, 0.8],
      y: [0, -1, 0]
    }}
 >
    <MessageSquarePlus size={20} className="text-gray-200" />
  </motion.div>
);

export const NewAgentIcon = () => (
  <motion.div
    initial={{ scale: 0.8 }}
    animate={{ 
      scale: [0.8, 1, 0.8],
      y: [0, -1, 0]
    }}
 >
    <UserPlus size={20} className="text-gray-200" />
  </motion.div>
);

export const ShareIcon = () => (
  <motion.div
    initial={{ scale: 0.8 }}
    animate={{ 
      scale: [0.8, 1, 0.8],
      rotate: [0, 15, -15, 0]
    }}
  >
    <Share2 size={20} className="text-gray-200" />
  </motion.div>
);

export const AgentIcon = () => (
  <FontAwesomeIcon 
    icon={faRobot}
    className="h-5 w-5 text-gray-200" 
  />
);

export const DatabaseIcon = () => (
  <motion.div
    initial={{ scale: 0.8 }}
    animate={{ 
      scale: [0.8, 1, 0.8],
      rotate: [0, 1, -1, 0]
    }}
  >
    <Database size={20} className="text-gray-200" />
  </motion.div>
);

export const FileIcon = () => (
  <Files size={16} className="w-4 h-4 text-gray-200" />
); 