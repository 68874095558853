import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Canvas } from '@react-three/fiber';
import { Float, Stars } from '@react-three/drei';
import { ReactTyped } from 'react-typed';
import DynamicNeuralNetwork from './DynamicNeuralNetwork';
import CookieConsent from './CookieConsent';
import Footer from './Footer';
import StatusDialog from './ui/StatusDialog';

// Animated background grid
const GridPattern = () => (
  <div className="absolute inset-0 z-0 opacity-[0.15]">
    <div className="absolute w-full h-full"
      style={{
        backgroundImage: `
          linear-gradient(to right, #304050 1px, transparent 1px),
          linear-gradient(to bottom, #304050 1px, transparent 1px)`,
        backgroundSize: '50px 50px',
      }}
    />
  </div>
);

const NoisePattern = () => (
  <div className="absolute inset-0 z-0">
    <div
      className="w-full h-full opacity-[0.15] mix-blend-soft-light"
      style={{
        backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.9' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
        filter: 'contrast(320%) brightness(1000%)',
      }}
    />
  </div>
);

// Update the button styles in the main content area
const ButtonBase = `
  group relative px-8 py-3 
  bg-gray-800/90 border border-blue-400/30 
  text-blue-100/90 rounded-lg font-light text-base sm:text-lg tracking-wider
  hover:bg-blue-500/5 transition-all duration-300
  backdrop-blur-sm shadow-lg shadow-black/20
  [text-shadow:_0_0_30px_rgb(59_130_246_/_0.2)]
`;

const InputBase = `
  px-6 py-3 
  bg-gray-800/90 border border-blue-400/30 
  text-blue-100/90 rounded-lg font-light tracking-wide
  focus:outline-none focus:border-blue-400/60 focus:ring-1 focus:ring-blue-400/30
  backdrop-blur-sm shadow-lg shadow-black/20
  [text-shadow:_0_0_30px_rgb(59_130_246_/_0.2)]
`;

const HomePage = ({ 
  handleSignIn, 
  handleWaitlistSignup, 
  waitlistStatus, 
  setWaitlistStatus, 
  email: userEmail 
}) => {
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(userEmail || '');
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showStatusDialog, setShowStatusDialog] = useState(false);

  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail);
    }
  }, [userEmail]);

  useEffect(() => {
    if (waitlistStatus && waitlistStatus !== 'approved') {
      setShowStatusDialog(true);
    }
  }, [waitlistStatus]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await handleWaitlistSignup(email);
      setWaitlistStatus(response.status);
      setShowEmailInput(false);
    } catch (error) {
      console.error('Error signing up for waitlist:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCookieAccept = () => {
    setShowCookieConsent(false);
    setShowTerms(true);
  };

  const handleCookieDeny = () => {
    setShowCookieConsent(false);
  };

  const handleDialogClose = () => {
    setShowStatusDialog(false);
    if (waitlistStatus === 'not_found') {
      setShowEmailInput(true);
    }
  };

  return (
    <div ref={containerRef} className="relative w-full h-screen overflow-hidden
      bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      {/* Background elements */}
      <div className="fixed pb-12 inset-0 z-0 bg-gradient-to-r from-gray-900/50 via-gray-900/50 to-gray-900/50 backdrop-blur-sm">
        <Canvas camera={{ position: [0, 0, 10], fov: 75 }}>
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} />
          <Float
            speed={1}
            rotationIntensity={0.2}
            floatIntensity={0.5}
          >
            <Stars 
              radius={400} 
              depth={50} 
              count={500} 
              factor={4} 
              saturation={0.8} 
              fade 
              speed={0.5} 
            />
            <group position={[0, 3, 0]} scale={window.innerWidth < 768 ? 0.6 : 1}>
              <DynamicNeuralNetwork />
            </group>
          </Float>
        </Canvas>
      </div>

      <GridPattern />
      <NoisePattern />

      {/* Animated gradient line */}
      <div className="absolute top-0 left-0 right-0 h-[1px] bg-gradient-to-r from-transparent via-blue-500/50 to-transparent 
        animate-gradient-x" />

      {/* Content wrapper */}
      <div className="relative z-10 w-full h-screen bg-gradient-to-b from-transparent via-gray-900/50 to-gray-800/90">
        <main className="flex flex-col items-center justify-between w-full h-screen">
          {/* Spacer for neural network */}
          <div className="h-[85vh] sm:h-[70vh]" />

          {/* Content section */}
          <div className="flex-1 flex flex-col items-center w-full px-4 pb-8 pt-8">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              className="text-center w-full max-w-4xl mx-auto"
            >
              {/* Glowing accent line */}
              <div className="w-24 h-[2px] mx-auto mt-8 mb-8 bg-gradient-to-r from-transparent via-blue-500/50 to-transparent" />

              <h2 className="text-xl sm:text-3xl md:text-4xl font-light tracking-wider mb-3
                bg-clip-text text-transparent bg-gradient-to-r from-blue-200 via-cyan-200 to-blue-200
                [text-shadow:_0_0_30px_rgb(59_130_246_/_0.2)]">
                <ReactTyped
                  strings={[
                    "ELEVATE HUMAN POTENTIAL",
                    "EMBRACE THE FUTURE",
                  ]}
                  typeSpeed={50}
                  backSpeed={30}
                  loop
                />
              </h2>

              <p className="max-w-3xl mx-auto text-sm sm:text-base text-blue-100/70 mb-8
                font-light tracking-wide">
                Where human intuition meets artificial intelligence, creating possibilities beyond imagination
              </p>

              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.9 }}
                className="flex flex-col items-center gap-4"
              >
                {waitlistStatus === 'pending' ? (
                  <div className="text-center space-y-4">
                    <div className="text-blue-200 mb-4 
                      [text-shadow:_0_0_30px_rgb(59_130_246_/_0.2)]">
                      <p className="text-lg mb-2">Thank you for your interest!</p>
                      <p className="text-sm text-blue-200/70">
                        You're currently on our waitlist. We'll notify you by email when your account is approved.
                      </p>
                    </div>
                  </div>
                ) : waitlistStatus === 'not_found' ? (
                  <div className="flex flex-col items-center gap-6">
                    <div className="text-center text-blue-200 mb-2
                      [text-shadow:_0_0_30px_rgb(59_130_246_/_0.2)]">
                      <p>Looks like you haven't joined our waitlist yet.</p>
                    </div>
                    {showEmailInput ? (
                      <form onSubmit={handleSubmit} className="flex justify-center items-center gap-4 w-full max-w-lg mx-auto">
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.3 }}
                          className="flex-1 max-w-sm"
                        >
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                            className={`${InputBase} w-full`}
                            autoFocus
                          />
                        </motion.div>
                        <motion.button
                          initial={{ opacity: 0, x: 20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.3 }}
                          type="submit"
                          disabled={isLoading}
                          className={ButtonBase}
                        >
                          {isLoading ? 'PROCESSING...' : 'JOIN'}
                        </motion.button>
                      </form>
                    ) : (
                      <button
                        onClick={() => setShowEmailInput(true)}
                        className={ButtonBase}
                      >
                        JOIN WAITLIST
                      </button>
                    )}
                  </div>
                ) : waitlistStatus === 'approved' ? (
                  <div className="flex flex-col items-center gap-4">
                    <div className="text-center text-blue-200 mb-2
                      [text-shadow:_0_0_30px_rgb(59_130_246_/_0.2)]">
                      <p>Welcome back! Your account has been approved.</p>
                    </div>
                    <button
                      onClick={handleSignIn}
                      className={ButtonBase}
                    >
                      SIGN IN
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col items-center gap-6">
                    {showEmailInput ? (
                      <form onSubmit={handleSubmit} className="flex justify-center items-center gap-4 w-full max-w-lg mx-auto">
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.3 }}
                          className="flex-1 max-w-sm"
                        >
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                            className={`${InputBase} w-full`}
                            autoFocus
                          />
                        </motion.div>
                        <motion.button
                          initial={{ opacity: 0, x: 20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.3 }}
                          type="submit"
                          disabled={isLoading}
                          className={ButtonBase}
                        >
                          {isLoading ? 'PROCESSING...' : 'JOIN'}
                        </motion.button>
                      </form>
                    ) : (
                      <button
                        onClick={() => setShowEmailInput(true)}
                        className={ButtonBase}
                      >
                        JOIN WAITLIST
                      </button>
                    )}
                    
                    {/* Always show the sign in link unless explicitly approved */}
                    {waitlistStatus !== 'approved' && (
                      <motion.button
                        onClick={handleSignIn}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.9 }}
                        transition={{ delay: 1.5 }}
                        whileHover={{ opacity: 1, scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className="text-sm sm:text-base text-blue-300/80 hover:text-blue-200 
                          tracking-wider transition-all duration-300 mb-6 sm:mb-0
                          [text-shadow:_0_0_30px_rgb(59_130_246_/_0.3)]
                          border-b border-blue-400/20 pb-0.5 hover:border-blue-400/40"
                      >
                        Already have an account? Sign in
                      </motion.button>
                    )}
                  </div>
                )}
              </motion.div>
            </motion.div>
          </div>

          {/* Footer section with padding */}
          <div className="w-full mt-auto pt-6">
            <CookieConsent 
              isOpen={showCookieConsent}
              onAccept={handleCookieAccept}
              onDeny={handleCookieDeny}
              setShowTerms={setShowTerms}
            />
            <Footer className="footer bg-gray-900 text-gray-200" />
          </div>
        </main>
      </div>

      <StatusDialog 
        isOpen={showStatusDialog}
        onClose={handleDialogClose}
        status={waitlistStatus}
        email={email}
      />
    </div>
  );
};

export default HomePage;