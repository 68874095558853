import React from 'react';
import { X } from 'lucide-react';
import useTokens from '../../hooks/useTokens';

const TokenUsageDetails = ({ onClose }) => {
    const { usage, getTierInfo, formatNumber } = useTokens();

    const formatDate = (timestamp) => {
        if (!timestamp) return 'Not available';
        
        return new Date(timestamp * 1000).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    if (!usage || !usage.limits || !usage.current) {
        return (
            <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
                <div className="bg-gray-900 rounded-lg shadow-xl w-[600px] mx-4 p-4">
                    <div className="text-center text-gray-400">Loading usage details...</div>
                </div>
            </div>
        );
    }

    const tierInfo = getTierInfo(usage.current.tier);
    const models = Object.values(usage.current.models || {});

    return (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-gray-900/95 rounded-lg shadow-xl w-[600px] mx-4">
                <div className="p-5 border-b border-gray-800">
                    <div className="flex justify-between items-start">
                        <div>
                            <h2 className="text-lg font-medium text-gray-200">
                                {usage.current.tier} Plan
                            </h2>
                            <div className="mt-2 font-mono">
                                <span className="text-2xl text-emerald-400/90">
                                    {formatNumber(usage.limits.remaining_requests)}
                                </span>
                                <span className="text-sm text-gray-500 ml-2">
                                    Requests Remaining
                                </span>
                            </div>
                        </div>
                        <button 
                            onClick={onClose} 
                            className="text-gray-500 hover:text-gray-300 p-1.5 rounded-full hover:bg-gray-800/80 transition-colors"
                        >
                            <X size={18} />
                        </button>
                    </div>
                </div>
                <div className="p-5 space-y-4">
                    <h3 className="text-sm font-medium text-gray-400">Current Month Usage</h3>
                    <div className="space-y-3">
                        {models.map((model) => (
                            <div key={model.name} className="bg-gray-800/40 rounded-lg p-4 hover:bg-gray-800/60 transition-colors">
                                <div className="flex justify-between items-center mb-3">
                                    <div className="flex flex-col">
                                        <span className="text-sm text-gray-300 font-medium">{model.name}</span>
                                        <span className="text-xs text-gray-500 mt-1">
                                            {formatNumber(model.user_requests)} requests
                                        </span>
                                    </div>
                                </div>
                                <div className="border-t border-gray-700/50 pt-3">
                                    <div className="flex justify-between items-center">
                                        <span className="text-xs text-gray-500">Tokens</span>
                                        <div className="flex gap-4 text-xs">
                                            <div className="flex flex-col items-end">
                                                <span className="text-gray-500">User</span>
                                                <span className="font-mono text-gray-300">{formatNumber(model.user_tokens || 0)}</span>
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <span className="text-gray-500">System</span>
                                                <span className="font-mono text-gray-300">{formatNumber(model.system_tokens || 0)}</span>
                                            </div>
                                            <div className="flex flex-col items-end">
                                                <span className="text-gray-500">Total</span>
                                                <span className="font-mono text-gray-300">{formatNumber(model.total_tokens || 0)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="p-5 border-t border-gray-800">
                    <div className="text-sm text-gray-500">
                        Next billing cycle starts {formatDate(usage.current.next_reset)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TokenUsageDetails; 