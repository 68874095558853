import React from 'react';
import { Terminal, Clipboard } from 'lucide-react';

const ToolOutputButton = ({ name, onClick }) => {
  const getIcon = () => {
    if (name === 'whiteboard') {
      return <Clipboard size={14} />;
    }
    return <Terminal size={14} />;
  };

  return (
    <button
      onClick={onClick}
      className="flex items-center gap-1.5 px-2 py-1 rounded text-xs
        bg-gray-700/50 hover:bg-gray-700/70 text-gray-300 hover:text-gray-100
        transition-colors duration-200"
    >
      {getIcon()}
      <span>{name}</span>
    </button>
  );
};

export default ToolOutputButton; 