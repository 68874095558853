import React, { useState, useEffect } from 'react';
import { Trash2, Save, Database, RefreshCw, Plus, X } from 'lucide-react';
import StatusPopup from './StatusPopup';
import ConfirmationDialog from './ConfirmationDialog';
import Modal from './ui/Modal';
import Button from './ui/Button';
import LoadingButton from './ui/LoadingButton';
import AgentForm from './AgentForm';
import DataSourceDialog from './DataSourceDialog';

const AgentEditDialog = ({ 
  isOpen, 
  onClose, 
  agent,
  updateAgent,
  isNewAgent,
  createAgent,
  isLoading,
  deleteAgent,
  resetAgent,
  agentData,
  getAgentData,
  addDataSource,
  removeDataSource,
  getAgentWithVersions,
  models
}) => {
  const [editableData, setEditableData] = useState({
    name: '',
    description: '',
    instructions: '',
    model: '',
    versions: []
  });
  const [error, setError] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeletingAgent, setIsDeletingAgent] = useState(false);

  useEffect(() => {
    const fetchAgentData = async () => {
      if (!agent) return;

      // Only fetch if we have an ID and haven't loaded this agent yet
      if (agent.id && !isNewAgent && !editableData.versions?.length) {
        try {
          const [agentWithVersions, datasources] = await Promise.all([
            getAgentWithVersions(agent.id),
            getAgentData(agent.id)
          ]);
          
          if (agentWithVersions?.agent) {
            setEditableData({
              name: agentWithVersions.agent.name || '',
              description: agentWithVersions.agent.description || '',
              instructions: agentWithVersions.agent.instructions || '',
              model: agentWithVersions.agent.model || '',
              versions: agentWithVersions.agent.versions || []
            });
          }
        } catch (error) {
          console.error('Error fetching agent data:', error);
          setError('Failed to load agent data');
        }
      } else {
        // For new agents or when editing without fetching
        setEditableData({
          name: agent.name || '',
          description: agent.description || '',
          instructions: agent.instructions || '',
          model: agent.model || '',
          versions: agent.versions || []
        });
      }
    };

    fetchAgentData();
  }, [agent?.id, isNewAgent]); // Only depend on agent.id and isNewAgent

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agent && !isNewAgent) return;

    try {
        if (isNewAgent) {
            await createAgent(editableData);
            onClose();
        } else {
            const updateResult = await updateAgent(agent.id, editableData);
            if (updateResult) {
                try {
                    await getAgentWithVersions(agent.id);
                    onClose();
                } catch (versionError) {
                    console.error('Error fetching updated agent versions:', versionError);
                    setError('Agent was updated but there was an error refreshing the data');
                    return;
                }
            }
        }
    } catch (error) {
        setError(error.message || 'An error occurred while saving the agent');
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setShowDeleteConfirmation(false);
      setIsDeletingAgent(true);
      await deleteAgent(agent.id);
      onClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDeletingAgent(false);
    }
  };

  const handleReset = async () => {
    try {
      const confirmed = window.confirm(
        "This will reset the agent to the latest default instructions. Any custom changes will be lost. Continue?"
      );
      if (confirmed) {
        await resetAgent(agent.id);
        onClose();
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isNewAgent ? 'New Agent' : 'Edit Agent'}
      maxWidth="xl"
      disabled={isDeletingAgent}
    >
      <form onSubmit={handleSubmit} className="flex flex-col max-h-[600px]">
        <div className="flex-1 overflow-y-auto px-6 py-4 bg-gray-900">
          <AgentForm 
            agentData={editableData}
            setAgentData={setEditableData}
            readOnly={false}
            models={models}
            onAddDataSource={async (request) => {
              try {
                await addDataSource(agent.id, request);
                const [updatedAgent, updatedData] = await Promise.all([
                  getAgentWithVersions(agent.id),
                  getAgentData(agent.id)
                ]);

                if (updatedAgent?.agent) {
                  setEditableData({
                    ...editableData,
                    versions: updatedAgent.agent.versions || []
                  });
                }
              } catch (error) {
                console.error('Error in AgentEditDialog addDataSource:', error);
              }
            }}
            onRemoveDataSource={async (dataSourceId) => {
              try {
                await removeDataSource(agent.id, dataSourceId);
                await getAgentData(agent.id);
              } catch (error) {
                console.error('Error in AgentEditDialog removeDataSource:', error);
              }
            }}
            dataSources={agent?.id && agentData ? (agentData[agent.id]?.data_sources || []) : []}
            isNewAgent={isNewAgent}
          />
        </div>

        <div className="flex justify-between items-center px-6 py-4 border-t border-gray-700/50 bg-gray-900">
          <div className="flex gap-2">
            {!isNewAgent && agent && (
              <>
                {agent.is_default ? (
                  <LoadingButton
                    variant="secondary"
                    onClick={handleReset}
                    isLoading={isLoading}
                    loadingText="Resetting..."
                    disabled={isLoading || isDeletingAgent}
                    icon={<RefreshCw size={20} />}
                  >
                    Reset
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    variant="danger"
                    onClick={handleDeleteClick}
                    isLoading={isDeletingAgent}
                    loadingText="Deleting..."
                    disabled={isLoading || isDeletingAgent}
                    icon={<Trash2 size={20} />}
                  >
                    Delete
                  </LoadingButton>
                )}
              </>
            )}
          </div>
          <div className="flex gap-3 ml-auto">
            <Button
              variant="secondary"
              onClick={onClose}
              disabled={isDeletingAgent}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              loadingText={isNewAgent ? "Creating..." : "Saving..."}
              disabled={isLoading}
              icon={!isLoading && <Save size={20} />}
            >
              {isNewAgent ? 'Create' : 'Save'}
            </LoadingButton>
          </div>
        </div>
      </form>

      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Delete Agent"
          message={`Are you sure you want to delete "${agent.name}"? This action cannot be undone.`}
          onConfirm={handleConfirmDelete}
          onCancel={() => setShowDeleteConfirmation(false)}
          confirmLabel="Delete"
          confirmStyle="danger"
        />
      )}

      {error && (
        <StatusPopup message={error} onClose={() => setError(null)} />
      )}
    </Modal>
  );
};

export default AgentEditDialog; 