import React from 'react';
import { ChevronDown } from 'lucide-react';

const ProjectDropdown = ({ projects, selectedProjectId, onSelect, onDelete, onEdit }) => {
  const selectedProject = projects.find(p => p.id === selectedProjectId);
  
  return (
    <div className="relative w-full">
      <select
        value={selectedProjectId || ''}
        onChange={(e) => {
          if (e.target.value === 'edit') {
            onEdit(projects.find(p => p.id === selectedProjectId));
          } else {
            onSelect(e.target.value);
          }
        }}
        className="w-full px-3 py-2.5 bg-gray-800/30 rounded-lg text-gray-200 appearance-none pr-10 
          focus:outline-none focus:ring-1 focus:ring-gray-600 text-ellipsis"
      >
        <option value="" disabled>Select a project</option>
        {projects.map(project => (
          <option 
            key={project.id} 
            value={project.id}
            className="truncate pr-6"
            title={project.name + (project.is_shared ? ' (Shared)' : '')}
          >
            {project.name}{project.is_shared ? ' 👥' : ''}
          </option>
        ))}
        {selectedProjectId && (
          <>
            <option value="" disabled>─────────────</option>
            <option value="edit" className="text-blue-400">Edit Project</option>
          </>
        )}
      </select>
      <ChevronDown className="absolute right-2.5 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none h-4 w-4" />
    </div>
  );
};

export default ProjectDropdown; 