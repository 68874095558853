import React, { useState, useEffect, useCallback, useRef } from 'react';
import SharedMarkdown from '../utils/SharedMarkdown';
import ProjectForm from './ProjectForm';
import AgentForm from './AgentForm';
import WhiteboardViewer from './WhiteboardViewer';

const ToolOutputViewer = ({ 
  output,
  projectData,
  agentData,
  updateProject,
  updateAgent,
  getAgentWithVersions,
  models,
  removeDataSource,
  addDataSource,
  getAgentData
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const loadedOutputRef = useRef(null);
  const originalDataRef = useRef(null);

  const loadData = useCallback(async () => {
    if (!output || loadedOutputRef.current === output.output) {
      return;
    }

    try {
      setIsLoading(true);
      if (output.name === "update_agent") {
        const outputData = JSON.parse(
          output.output
            .replace(/'/g, '"')
            .replace(/None/g, 'null')
        );
        
        const agentId = outputData.agent_id;
        if (!agentId) {
          console.error('No agent ID in output:', outputData);
          return;
        }

        // Simple Promise.all without abort signal
        const [agentWithVersions, agentDataSources] = await Promise.all([
          getAgentWithVersions(agentId),
          getAgentData(agentId)
        ]);

        if (agentWithVersions?.agent) {
          const initialData = {
            name: agentWithVersions.agent.name || '',
            description: agentWithVersions.agent.description || '',
            instructions: agentWithVersions.agent.instructions || '',
            model: agentWithVersions.agent.model || '',
            versions: agentWithVersions.agent.versions || []
          };
          originalDataRef.current = initialData;
          setEditableData(initialData);
          loadedOutputRef.current = output.output;
        }
      } else if (output.name === "update_project" && projectData) {
        const outputData = JSON.parse(
          output.output
            .replace(/'/g, '"')
            .replace(/None/g, 'null')
        );
        
        const projectId = outputData.project_id;
        if (!projectId) {
          console.error('No project ID in output:', outputData);
          return;
        }

        if (projectData && projectData.id === projectId) {
          setEditableData({...projectData});
          loadedOutputRef.current = output.output;
        }
      }
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [output, projectData, getAgentWithVersions, getAgentData]);

  useEffect(() => {
    if (output?.output) {
      loadData();
    }
  }, [loadData]);

  if (!output) return null;

  const handleSave = async (isProject, sourceData) => {
    try {
      if (!editableData || !output) return;
        
      // Get the agent ID from the parsed output data
      const outputData = JSON.parse(
        output.output
          .replace(/'/g, '"')
          .replace(/None/g, 'null')
      );
        
      if (isProject) {
        await updateProject(sourceData.id, editableData);
      } else {
        const agentId = outputData.agent_id;
        if (!agentId) {
          throw new Error('No agent ID found in output data');
        }
            
        // Ensure we're passing the correct data structure
        const { name, description, instructions, model } = editableData;
            
        // Validate data before sending
        if (typeof name !== 'string' || !name) {
          throw new Error('Invalid agent name');
        }
            
        // Pass individual fields to match useAgents.updateAgent signature
        const updateResult = await updateAgent(
          agentId,
          name,
          description || '',
          instructions || '',
          model || ''
        );

        if (updateResult) {
          await getAgentWithVersions(agentId);
        }
      }
      setIsEditing(false);
    } catch (error) {
      console.error(`Error updating ${isProject ? 'project' : 'agent'}:`, error);
      // Re-throw to allow parent components to handle the error
      throw error;
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (originalDataRef.current) {
      setEditableData({...originalDataRef.current});
    }
  };

  const renderForm = () => {
    if (!output) return null;

    const isProject = output.name === "update_project";
    const Form = isProject ? ProjectForm : AgentForm;
    
    // Parse output to get agent/project ID
    let outputData;
    try {
      outputData = JSON.parse(
        output.output
          .replace(/'/g, '"')
          .replace(/None/g, 'null')
      );
    } catch (error) {
      console.error('Error parsing output:', error);
      return null;
    }

    const sourceId = isProject ? outputData.project_id : outputData.agent_id;
    const sourceData = isProject ? projectData : (agentData?.[sourceId] || {});

    // Parse output to get change summary
    let changeSummary = '';
    try {
      changeSummary = outputData.change_summary;
    } catch (error) {
      console.error('Error parsing change summary:', error);
    }

    if (isLoading && ((isProject && !projectData) || (!isProject && !agentData && !projectData))) {
      return (
        <div className="p-4 flex items-center justify-center text-gray-400">
          <div className="flex flex-col items-center space-y-2">
            <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
              <circle 
                className="opacity-25" 
                cx="12" 
                cy="12" 
                r="10" 
                stroke="currentColor" 
                strokeWidth="4"
                fill="none"
              />
              <path 
                className="opacity-75" 
                fill="currentColor" 
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            <span>Loading {isProject ? 'project' : 'agent'} data...</span>
          </div>
        </div>
      );
    }

    if (!sourceData || !editableData) {
      return (
        <div className="p-4 text-gray-400 text-center">
          No {isProject ? 'project' : 'agent'} data available
        </div>
      );
    }

    return (
      <div className="p-4">
        <Form 
          data={editableData}
          setData={setEditableData}
          isEditing={isEditing}
          models={models}
          removeDataSource={removeDataSource}
          addDataSource={addDataSource}
          changeSummary={changeSummary}
        />
        <div className="mt-4 flex justify-end gap-2">
          {isEditing && (
            <>
              <button 
                onClick={() => handleCancel()}
                className="px-3 py-1.5 text-sm text-gray-300 hover:text-white"
              >
                Cancel
              </button>
              <button 
                onClick={() => handleSave(isProject, sourceData)}
                className="px-3 py-1.5 text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Save Changes
              </button>
            </>
          )}
          {!isEditing && (
            <button 
              onClick={() => setIsEditing(true)}
              className="px-3 py-1.5 text-sm text-blue-400 hover:text-blue-300"
            >
              Edit {isProject ? 'Project' : 'Agent'}
            </button>
          )}
        </div>
      </div>
    );
  };

  // If it's a whiteboard tool output, show the WhiteboardViewer
  if (output?.name === "whiteboard") {
    try {
      // Parse the JSON output directly since backend handles escaping
      let outputData = JSON.parse(output.output);

      // Validate required fields
      if (!outputData?.project_id || !outputData?.conversation_id) {
        throw new Error('Missing required project_id or conversation_id in output data');
      }

      return (
        <div className="flex flex-col h-full">
          <WhiteboardViewer 
            projectId={outputData.project_id} 
            conversationId={outputData.conversation_id}
          />
        </div>
      );
    } catch (error) {
      console.error('Error handling whiteboard output:', error);
      console.error('Tool output:', output);
      
      // Render error state instead of returning null
      return (
        <div className="flex flex-col items-center justify-center h-full text-gray-400 p-4">
          <div className="text-center">
            <p className="mb-2">Failed to load whiteboard viewer</p>
            <p className="text-sm opacity-75">Please try refreshing the page</p>
          </div>
        </div>
      );
    }
  }

  // If it's a project update or agent update, show editable form
  if (output?.name === "update_project" || output?.name === "update_agent") {
    return renderForm();
  }

  // Default tool output rendering
  return (
    <div className="flex flex-col h-full">
      {output?.parameters && Object.keys(output.parameters).length > 0 && (
        <details className="mb-2 text-xs">
          <summary className="cursor-pointer text-gray-400 hover:text-gray-300 select-none">
            Parameters
          </summary>
          <pre className="mt-2 text-gray-300 bg-gray-800/50 p-2 rounded overflow-x-auto">
            {JSON.stringify(output.parameters, null, 2)}
          </pre>
        </details>
      )}
      
      <div className="flex-1 overflow-y-auto">
        <SharedMarkdown className="text-gray-200 text-xs leading-relaxed" isWorkbench={true}>
          {output?.output || ''}
        </SharedMarkdown>
      </div>
    </div>
  );
};

export default ToolOutputViewer; 