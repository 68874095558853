import React, { useRef, useMemo, useState, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const DynamicNeuralNetwork = () => {
  const groupRef = useRef();
  const { viewport } = useThree();
  const [isDragging, setIsDragging] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const rotationVelocityRef = useRef({ x: 0, y: 0 });
  const lastMousePos = useRef({ x: 0, y: 0 });
  const lastUpdateTime = useRef(Date.now());
  const MAX_VELOCITY = 0.05;

  // Detect if device is mobile
  const isMobile = useMemo(() => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }, []);

  // Add touch and mouse interaction listeners
  useEffect(() => {
    const handleStart = (event) => {
      event.preventDefault(); // Prevent default touch behavior
      setIsDragging(true);
      const point = event.touches ? event.touches[0] : event;
      lastMousePos.current = {
        x: point.clientX,
        y: point.clientY
      };
      lastUpdateTime.current = Date.now();
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    const handleMove = (event) => {
      if (!isDragging || !groupRef.current) return;
      event.preventDefault(); // Prevent default touch behavior
      
      const point = event.touches ? event.touches[0] : event;
      const currentTime = Date.now();
      const deltaTime = Math.max((currentTime - lastUpdateTime.current) / 1000, 0.016);
      
      const deltaX = point.clientX - lastMousePos.current.x;
      const deltaY = point.clientY - lastMousePos.current.y;

      // Reduced rotation sensitivity for mobile
      const sensitivity = isMobile ? 0.003 : 0.005;
      groupRef.current.rotation.y -= (deltaX * sensitivity);
      groupRef.current.rotation.x -= (deltaY * sensitivity);

      rotationVelocityRef.current = {
        x: -deltaY * sensitivity,
        y: -deltaX * sensitivity
      };

      lastMousePos.current = {
        x: point.clientX,
        y: point.clientY
      };
      lastUpdateTime.current = currentTime;
    };

    // Add mouse event listeners
    window.addEventListener('mousedown', handleStart);
    window.addEventListener('mouseup', handleEnd);
    window.addEventListener('mousemove', handleMove);
    window.addEventListener('mouseleave', handleEnd);

    // Add touch event listeners
    window.addEventListener('touchstart', handleStart);
    window.addEventListener('touchend', handleEnd);
    window.addEventListener('touchmove', handleMove);
    window.addEventListener('touchcancel', handleEnd);

    return () => {
      // Remove mouse event listeners
      window.removeEventListener('mousedown', handleStart);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('mouseleave', handleEnd);

      // Remove touch event listeners
      window.removeEventListener('touchstart', handleStart);
      window.removeEventListener('touchend', handleEnd);
      window.removeEventListener('touchmove', handleMove);
      window.removeEventListener('touchcancel', handleEnd);
    };
  }, [isDragging, isMobile]);

  // Generate nodes with reduced count for mobile
  const nodes = useMemo(() => {
    const nodeCount = isMobile ? 35 : 100;
    const positions = [];
    const colors = [];
    
    for (let i = 0; i < nodeCount; i++) {
      // Increased radius for desktop
      const radius = isMobile ? 2.5 : 3.5;
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos((Math.random() * 2) - 1);
      
      positions.push(
        radius * Math.sin(phi) * Math.cos(theta),
        radius * Math.sin(phi) * Math.sin(theta),
        radius * Math.cos(phi)
      );

      // Simplified colors for better mobile performance
      const intensity = 0.5 + Math.random() * 0.5;
      colors.push(
        0.2,
        0.6 + Math.random() * 0.4,
        1.0
      );
    }

    return {
      positions: new Float32Array(positions),
      colors: new Float32Array(colors)
    };
  }, [isMobile]);

  // Generate connections with reduced count for mobile
  const connections = useMemo(() => {
    const positions = [];
    const colors = [];
    const nodePositions = nodes.positions;
    const maxConnections = isMobile ? 75 : 200; // Keep more connections on desktop
    
    for (let i = 0; i < maxConnections; i++) {
      const startIndex = Math.floor(Math.random() * (nodePositions.length / 3)) * 3;
      const nearbyIndex = (startIndex + Math.floor(Math.random() * 5) * 3) % nodePositions.length;
      
      positions.push(
        nodePositions[startIndex], nodePositions[startIndex + 1], nodePositions[startIndex + 2],
        nodePositions[nearbyIndex], nodePositions[nearbyIndex + 1], nodePositions[nearbyIndex + 2]
      );

      // Simplified colors for mobile
      colors.push(
        0.0, 0.9, 1.0,
        0.0, 0.4, 1.0
      );
    }

    return {
      positions: new Float32Array(positions),
      colors: new Float32Array(colors)
    };
  }, [nodes, isMobile]);

  // Create geometries
  const nodeGeometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(nodes.positions, 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(nodes.colors, 3));
    return geometry;
  }, [nodes]);

  const connectionGeometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(connections.positions, 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(connections.colors, 3));
    return geometry;
  }, [connections]);

  // Optimized animation frame
  useFrame(({ clock }) => {
    if (!groupRef.current) return;

    const time = clock.getElapsedTime() * (isMobile ? 1 : 1.5);
    
    if (isDragging) {
      groupRef.current.rotation.x += rotationVelocityRef.current.x;
      groupRef.current.rotation.y += rotationVelocityRef.current.y;
    } else {
      const momentumDecay = isMobile ? 0.92 : 0.95;
      rotationVelocityRef.current = {
        x: rotationVelocityRef.current.x * momentumDecay,
        y: rotationVelocityRef.current.y * momentumDecay
      };
      
      groupRef.current.rotation.x += rotationVelocityRef.current.x;
      groupRef.current.rotation.y += rotationVelocityRef.current.y;

      // Only keep simple rotation, remove oscillation
      if (Math.abs(rotationVelocityRef.current.x) < 0.001 && 
          Math.abs(rotationVelocityRef.current.y) < 0.001) {
        const rotationSpeed = isMobile ? 0.0003 : 0.0005;
        groupRef.current.rotation.y += rotationSpeed;
      }
    }
    
    // Ensure rotation values stay within bounds
    groupRef.current.rotation.x = groupRef.current.rotation.x % (Math.PI * 2);
    groupRef.current.rotation.y = groupRef.current.rotation.y % (Math.PI * 2);
    
    // Remove breathing effect, keep constant scale
    const scale = isHovering ? 1.02 : 1;
    groupRef.current.scale.set(scale, scale, scale);
  });

  return (
    <group 
      ref={groupRef}
      onPointerEnter={() => !isMobile && setIsHovering(true)}
      onPointerLeave={() => !isMobile && setIsHovering(false)}
    >
      {/* Connections */}
      <lineSegments geometry={connectionGeometry}>
        <lineBasicMaterial
          vertexColors
          transparent
          opacity={0.35}
          blending={THREE.AdditiveBlending}
          depthWrite={false}
          linewidth={1}
        />
      </lineSegments>

      {/* Nodes */}
      <points geometry={nodeGeometry}>
        <pointsMaterial
          size={isMobile ? 0.15 : 0.2}
          vertexColors
          transparent
          opacity={0.7}
          sizeAttenuation={true}
          depthWrite={false}
          blending={THREE.AdditiveBlending}
        />
      </points>
    </group>
  );
};

export default DynamicNeuralNetwork; 